import { combineReducers } from 'redux';

// import authReducer from 'redux/auth/reducers';
import userReducer from 'redux/users/reducers';
import toastReducer from 'redux/toast/reducers';
// import companySettingReducer from 'redux/settings/company/reducers';
import appSettingReducer from 'redux/settings/app/reducers';
// import calendarSettingReducer from 'redux/settings/calendar/reducers';
// import notificationSettingReducer from 'redux/settings/notification/reducers';
// import clientHubReducer from 'redux/settings/clientHub/reducers';
// import categoryReducer from 'redux/categories/reducers';
// import productReducer from 'redux/products/reducers';
// import contactReducer from 'redux/contacts/reducers';
import documentReducer from 'redux/documents/reducers';
// import templateReducer from 'redux/templates/reducers';
// import salesReducer from 'redux/sales/reducers';
// import jobsReducer from 'redux/jobs/reducers';
// import pipelineReducer from 'redux/pipelines/reducers';
import appointmentsReducer from 'redux/appointments/reducers';
// import bookingformReducer from 'redux/bookingForm/reducers';
// import jobScheduleReducer from 'redux/jobSchedule/reducers';
// import taskManagementReducer from 'redux/taskManagement/reducers';
// import dripsReducer from 'redux/drips/reducers';
import notificationReducer from 'redux/notifications/reducers';
import invoicesReducer from 'redux/HomeOwner/invoices/reducers';

// ---------------------------- HOMEOWNER --------------------------------
import authReducer from 'redux/HomeOwner/auth/reducers';
import projectReducer from 'redux/HomeOwner/myProjects/reducers';
import myProposalsReducer from 'redux/HomeOwner/myProposals/reducers';
import proposalsReducer from 'redux/proposals/reducers';
import paymentSettingsReducer from 'redux/payments/reducers';

export default combineReducers({
  user: userReducer,
  toast: toastReducer,
  // companySetting: companySettingReducer,
  appSetting: appSettingReducer,
  // calendarSetting: calendarSettingReducer,
  // notificationSetting: notificationSettingReducer,
  // clientHub: clientHubReducer,
  // category: categoryReducer,
  // product: productReducer,
  proposal: proposalsReducer,
  // contact: contactReducer,
  document: documentReducer,
  // template: templateReducer,
  // sales: salesReducer,
  // jobs: jobsReducer,
  // pipeline: pipelineReducer,
  appointments: appointmentsReducer,
  // bookingForm: bookingformReducer,
  // jobSchedule: jobScheduleReducer,
  // taskManagement: taskManagementReducer,
  // dripsReducer: dripsReducer,
  notification: notificationReducer,
  auth: authReducer,
  project: projectReducer,
  myProposals: myProposalsReducer,
  invoice: invoicesReducer,
  paymentSetting: paymentSettingsReducer,
});
