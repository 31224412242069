import React from 'react';
import { Box } from '@mui/material';
import Button from 'components/Button';
import { notifyFilterBtnData } from 'data/notifyData';

const NotifyTabs = ({ tabValue, ...props }) => {
  const handleSwitchButton = (type) => {
    props?.setTabValue(type);
  };

  return (
    <Box display={'flex'}>
      {notifyFilterBtnData?.map((item, index) => {
        return (
          <Box key={index}>
            {index === 0 ? (
              <Button
                className={
                  tabValue === item?.value
                    ? 'filter_all_count'
                    : 'notify_filter_btn'
                }
                type="Button"
                onClick={() => handleSwitchButton(item?.value)}
              >
                {item?.name}
                <span className="filter_count">
                {props?.count > 0 ? props?.count : 0}
                </span>
              </Button>
            ) : (
              <Button
                className={
                  tabValue === item?.value
                    ? 'filter_all_count'
                    : 'notify_filter_btn'
                }
                type="Button"
                onClick={() => handleSwitchButton(item?.value)}
              >
                {item?.name}
              </Button>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default NotifyTabs;
