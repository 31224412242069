import * as constants from 'redux/users/constants';

const initialState = {
  loading: true,
  users: [],
  creating: false,
  updating: false,
  deleting: false,
  refetch: false,
  loadingRoles: true,
  roles: [],
  loadingUser: false,
  user: null,
  loadingNotes: false,
  notes: [],
  creatingNote: false,
  deletingNote: false,
  loadingAttachments: false,
  attachments: [],
  creatingAttachment: false,
  deletingAttachment: false,
  progress: 0,
  fetchUsers: false,
  allUsers: [],
};

export default function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case constants.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        refetch: false,
        creating: false,
        updating: false,
        deleting: false,
        users: payload,
      };

    case constants.GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case constants.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        loading: false,
        fetchUsers: true,
      };

    case constants.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        refetch: false,
        fetchUsers: false,
        allUsers: payload,
      };

    case constants.GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        refetch: false,
        fetchUsers: false,
      };

    case constants.GET_ROLES_REQUEST:
      return {
        ...state,
        loadingRoles: true,
      };

    case constants.GET_ROLES_SUCCESS:
      return {
        ...state,
        loadingRoles: false,
        roles: payload,
      };

    case constants.GET_ROLES_FAIL:
      return {
        ...state,
        loadingRoles: false,
      };

    case constants.CREATE_USER_REQUEST:
      return {
        ...state,
        creating: true,
      };

    case constants.CREATE_USER_SUCCESS:
      return {
        ...state,
        creating: false,
        refetch: true,
      };

    case constants.CREATE_USER_FAIL:
      return {
        ...state,
        creating: false,
      };

    case constants.UPDATE_USER_REQUEST:
      return {
        ...state,
        updating: true,
      };

    case constants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        refetch: true,
      };

    case constants.UPDATE_USER_FAIL:
      return {
        ...state,
        updating: false,
      };

    case constants.DELETE_USER_REQUEST:
      return {
        ...state,
        deleting: payload,
      };

    case constants.DELETE_USER_SUCCESS:
      const copyRows = [...state?.users?.rows];
      const updatedRows = copyRows?.filter((e) => e?.id !== payload?.userId);

      return {
        ...state,
        refetch: true,
        users: {
          ...copyRows?.users,
          rows: updatedRows,
        },
      };

    case constants.DELETE_USER_FAIL:
      return {
        ...state,
        deleting: false,
      };

    case constants.GET_USER_REQUEST:
      return {
        ...state,
        loadingUser: true,
      };

    case constants.GET_USER_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        user: payload,
      };

    case constants.GET_USER_FAIL:
      return {
        ...state,
        loadingUser: false,
      };

    case constants.GET_USER_NOTES_REQUEST:
      return {
        ...state,
        loadingNotes: true,
      };

    case constants.GET_USER_NOTES_SUCCESS:
      return {
        ...state,
        loadingNotes: false,
        deletingNote: false,
        refetch: false,
        notes: payload,
      };

    case constants.GET_USER_NOTES_FAIL:
      return {
        ...state,
        loadingNotes: false,
      };

    case constants.CREATE_USER_NOTE_REQUEST:
      return {
        ...state,
        creatingNote: true,
      };

    case constants.CREATE_USER_NOTE_SUCCESS:
      return {
        ...state,
        creatingNote: false,
        refetch: true,
      };

    case constants.CREATE_USER_NOTE_FAIL:
      return {
        ...state,
        creatingNote: false,
      };

    case constants.DELETE_USER_NOTE_REQUEST:
      return {
        ...state,
        deletingNote: payload,
      };

    case constants.DELETE_USER_NOTE_SUCCESS:
    case constants.DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        refetch: true,
      };

    case constants.DELETE_USER_NOTE_FAIL:
      return {
        ...state,
        deletingNote: false,
      };

    case constants.GET_ATTACHMENTS_REQUEST:
      return {
        ...state,
        loadingAttachments: true,
      };

    case constants.GET_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        loadingAttachments: false,
        deletingAttachment: false,
        refetch: false,
        attachments: payload,
      };

    case constants.GET_ATTACHMENTS_FAIL:
      return {
        ...state,
        loadingAttachments: false,
      };

    case constants.CREATE_ATTACHMENT_REQUEST:
      return {
        ...state,
        creatingAttachment: true,
      };

    case constants.CREATE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        creatingAttachment: false,
        refetch: true,
      };

    case constants.CREATE_ATTACHMENT_FAIL:
      return {
        ...state,
        creatingAttachment: false,
      };

    case constants.DELETE_ATTACHMENT_REQUEST:
      return {
        ...state,
        deletingAttachment: payload,
      };

    case constants.DELETE_ATTACHMENT_FAIL:
      return {
        ...state,
        deletingAttachment: false,
      };

    case constants.ATTACHMENT_PROGRESS:
      return {
        ...state,
        progress: payload,
      };

    case constants.RESET_ATTACHMENT_PROGRESS:
      return {
        ...state,
        progress: 0,
      };

    case constants.RESET_USER_REFETCH:
      return {
        ...state,
        refetch: false,
        user: null,
      };

    case constants.UPDATE_USERS_PAGE:
      return {
        ...state,
        users: {
          ...state.users,
          page: payload,
        },
      };
    default:
      return state;
  }
}
