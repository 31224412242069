import { lazy } from 'react';

const Register = lazy(() => import('pages/HomeOwner/Register'));
const Login = lazy(() => import('pages/HomeOwner/Login'));
const AccountVerify = lazy(() => import('pages/HomeOwner/AccountVerify'));
// const SetAccountPassword = lazy(() => import('pages/SetAccountPassword'));

const publicRoutes = [
  {
    id: 1,
    title: 'Register',
    icon: null,
    path: '/register',
    component: <Register />,
  },
  {
    id: 2,
    title: 'Login',
    icon: null,
    path: '/',
    component: <Login />,
  },
  {
    id: 3,
    title: 'Login',
    icon: null,
    path: '/login',
    component: <Login />,
  },
  {
    id: 4,
    title: 'Account Verify',
    icon: null,
    path: '/verify/:ownerId',
    component: <AccountVerify />,
  }
];

export default publicRoutes;
