export const GET_ALL_PROJECT_REQUEST = 'GET_ALL_PROJECT_REQUEST';
export const GET_ALL_PROJECT_SUCCESS = 'GET_ALL_PROJECT_SUCCESS';
export const GET_ALL_PROJECT_FAIL = 'GET_ALL_PROJECT_FAIL';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';

export const GET_PROJECT_BY_PROJECT_ID_REQUEST = 'GET_PROJECT_BY_PROJECT_ID_REQUEST';
export const GET_PROJECT_BY_PROJECT_ID_SUCCESS = 'GET_PROJECT_BY_PROJECT_ID_SUCCESS';
export const GET_PROJECT_BY_PROJECT_ID_FAIL = 'GET_PROJECT_BY_PROJECT_ID_FAIL';

export const PROJECT_IMG_PROGRESS = 'PROJECT_IMG_PROGRESS';
export const RESET_IMG_PROGRESS = 'RESET_IMG_PROGRESS';

export const UPLOAD_PROJECT_IMG_REQUEST = 'UPLOAD_PROJECT_IMG_REQUEST';
export const UPLOAD_PROJECT_IMG_SUCCESS = 'UPLOAD_PROJECT_IMG_SUCCESS';
export const UPLOAD_PROJECT_IMG_FAIL = 'UPLOAD_PROJECT_IMG_FAIL';

export const DELETE_PROJECT_IMG_REQUEST = 'DELETE_PROJECT_IMG_REQUEST';
export const DELETE_PROJECT_IMG_SUCCESS = 'DELETE_PROJECT_IMG_SUCCESS';
export const DELETE_PROJECT_IMG_FAIL = 'DELETE_PROJECT_IMG_FAIL';

export const GET_INVITED_CONTRACTOR_REQUEST = 'GET_INVITED_CONTRACTOR_REQUEST';
export const GET_INVITED_CONTRACTOR_SUCCESS = 'GET_INVITED_CONTRACTOR_SUCCESS';
export const GET_INVITED_CONTRACTOR_FAIL = 'GET_INVITED_CONTRACTOR_FAIL';

export const SEND_INVITE_TO_CONTRACTOR_REQUEST = 'SEND_INVITE_TO_CONTRACTOR_REQUEST';
export const SEND_INVITE_TO_CONTRACTOR_SUCCESS = 'SEND_INVITE_TO_CONTRACTOR_SUCCESS';
export const SEND_INVITE_TO_CONTRACTOR_FAIL = 'SEND_INVITE_TO_CONTRACTOR_FAIL';

export const UPDATE_INVITED_CONTRACTOR_REQUEST = 'UPDATE_INVITED_CONTRACTOR_REQUEST';
export const UPDATE_INVITED_CONTRACTOR_SUCCESS = 'UPDATE_INVITED_CONTRACTOR_SUCCESS';
export const UPDATE_INVITED_CONTRACTOR_FAIL = 'UPDATE_INVITED_CONTRACTOR_FAIL';

export const GET_ALL_CONTRACTOR_REQUEST = 'GET_ALL_CONTRACTOR_REQUEST';
export const GET_ALL_CONTRACTOR_SUCCESS = 'GET_ALL_CONTRACTOR_SUCCESS';
export const GET_ALL_CONTRACTOR_FAIL = 'GET_ALL_CONTRACTOR_FAIL';

export const RESET_REFETCH = 'RESET_REFETCH';