import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from 'components/Button';
import NotifyTabs from 'components/PprivateLayout/NotifyBarContent/NotifyTabs';
import NotifySettingsModal from 'components/PprivateLayout/NotifySettingsModal';

import BackDropProgressBar from 'components/BackDropProgressBar';

const NotifyBarContent = ({ setNotify, ...props }) => {
  const [settingsModal, setSettingsModal] = useState(false);

  const handleOpenModal = () => {
    setSettingsModal(true);
    setNotify(null);
  };

  const handleCloseModal = () => {
    setSettingsModal(false);
    setNotify(null);
  };

  if (settingsModal) {
    return (
      <NotifySettingsModal
        open={settingsModal}
        handleClose={handleCloseModal}
        notifyUnreadCount={props?.notifyUnreadCount}
        handleUpdateMarkNotification={props?.handleUpdateMarkNotification}
      />
    );
  }

  if (props?.loading) {
    return <BackDropProgressBar loading={props?.loading} />;
  }

  return (
    <Box className="notify_main_content">
      <Box className="notify_settings_header">
        <Typography color={'#000'} fontWeight={600} fontSize={18}>
          Notifications
        </Typography>
        <Button
          className="notify_bell_settings_icon"
          type="button"
          onClick={handleOpenModal}
        >
          <SettingsIcon />
        </Button>
      </Box>

      <Box className="notify_tabs">
        <NotifyTabs
          tabValue={props?.tabValue}
          setTabValue={props?.setTabValue}
          count={props?.notifyCount}
        />
      </Box>
    </Box>
  );
};

export default NotifyBarContent;
