export const GET_HEAR_ABOUT_US_API = 'admin/registration-source/all';
export const REGISTER_API = 'home-owner/signup';
export const ACCOUNT_VERIFY_API = 'home-owner/verify';
export const LOGIN_API = 'home-owner/login';
export const GET_USER_API = 'home-owner/user';
export const FORGET_PASSWORD_API = 'home-owner/password-reset';

//---------------CHANGE USER PASSWORD--------------------

export const CHANGE_USER_PASSWORD_API = 'home-owner/password-change';

//---------------UPDATE USER PROFILE--------------------

export const UPDATE_USER_PROFILE_API = 'home-owner/update-profile';
