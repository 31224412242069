import * as Yup from 'yup';

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('Current Password is required'),
  newPassword: Yup.string()
    .min(8, 'New Password must be at least 8 characters')
    .required('New Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

export default validationSchema;
