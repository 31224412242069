import * as constants from 'redux/documents/constants';

const initialState = {
  loading: true,
  documents: [],
  uploading: false,
  deleting: false,
  refetch: false,
  progress: 0,
  updating: false,
  documentPDF: null,
};

export default function documentsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.GET_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case constants.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        uploading: false,
        deleting: false,
        refetch: false,
        documents: payload,
      };

    case constants.GET_DOCUMENTS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case constants.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        uploading: true,
      };

    case constants.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploading: false,
        refetch: true,
        documentPDF: payload,
      };

    case constants.UPLOAD_DOCUMENT_FAIL:
      return {
        ...state,
        uploading: false,
      };

    case constants.DOCUMENT_PROGRESS:
      return {
        ...state,
        progress: payload,
      };

    case constants.UPDATE_DOCUMENT_NAME_REQUEST:
      return {
        ...state,
        updating: true,
      };

    case constants.UPDATE_DOCUMENT_NAME_SUCCESS:
      return {
        ...state,
        updating: payload,
        refetch: true,
      };

    case constants.UPDATE_DOCUMENT_NAME_FAIL:
      return {
        ...state,
        updating: false,
      };

    case constants.RESET_DOCUMENT_PROGRESS:
      return {
        ...state,
        progress: 0,
      };

    case constants.DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        deleting: payload,
      };

    case constants.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        refetch: true,
      };

    case constants.DELETE_DOCUMENT_FAIL:
      return {
        ...state,
        deleting: false,
      };

    case constants.RESET_DOCUMENTS_REFETCH:
      return {
        ...state,
        refetch: false,
      };

    case constants.UPDATE_DOCUMENTS_PAGE:
      return {
        ...state,
        documents: {
          ...state.documents,
          page: payload,
        },
      };

    default:
      return state;
  }
}
