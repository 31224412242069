//---------------GET ALL EVENTS APPOINTMENTS-------------------
export const GET_EVENT_APPOINTMENTS_REQUEST = 'GET_EVENT_APPOINTMENTS_REQUEST';
export const GET_EVENT_APPOINTMENTS_SUCCESS = 'GET_EVENT_APPOINTMENTS_SUCCESS';
export const GET_EVENT_APPOINTMENTS_FAIL = 'GET_EVENT_APPOINTMENTS_FAIL';

//---------------GET ALL CALENDER APPOINTMENTS-------------------
export const GET_ALL_APPOINTMENTS_REQUEST = 'GET_ALL_APPOINTMENTS_REQUEST';
export const GET_ALL_APPOINTMENTS_SUCCESS = 'GET_ALL_APPOINTMENTS_SUCCESS';
export const GET_ALL_APPOINTMENTS_FAIL = 'GET_ALL_APPOINTMENTS_FAIL';

//---------------CREATE NEW APPOINTMENT-------------------
export const CREATE_NEW_APPOINTMENT_REQUEST = 'CREATE_NEW_APPOINTMENT_REQUEST';
export const CREATE_NEW_APPOINTMENT_SUCCESS = 'CREATE_NEW_APPOINTMENT_SUCCESS';
export const CREATE_NEW_APPOINTMENT_FAIL = 'CREATE_NEW_APPOINTMENT_FAIL';

//---------------UPDATE SINGLE APPOINTMENT-------------------
export const UPDATE_APPOINTMENT_REQUEST = 'UPDATE_APPOINTMENT_REQUEST';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAIL = 'UPDATE_APPOINTMENT_FAIL';

//---------------GET APPOINTMENT BY ID-------------------
export const GET_APPOINTMENT_BY_ID_REQUEST = 'GET_APPOINTMENT_BY_ID_REQUEST';
export const GET_APPOINTMENT_BY_ID_SUCCESS = 'GET_APPOINTMENT_BY_ID_SUCCESS';
export const GET_APPOINTMENT_BY_ID_FAIL = 'GET_APPOINTMENT_BY_ID_FAIL';

//---------------DELETE APPOINTMENT BY ID-------------------
export const DELETE_APPOINTMENT_BY_ID_REQUEST =
  'DELETE_APPOINTMENT_BY_ID_REQUEST';
export const DELETE_APPOINTMENT_BY_ID_SUCCESS =
  'DELETE_APPOINTMENT_BY_ID_SUCCESS';
export const DELETE_APPOINTMENT_BY_ID_FAIL = 'DELETE_APPOINTMENT_BY_ID_FAIL';

//---------------GET APPOINTMENT REQUEST-------------------
export const GET_APPOINTMENT_REQUEST_FORM_REQUEST =
  'GET_APPOINTMENT_REQUEST_FORM_REQUEST';
export const GET_APPOINTMENT_REQUEST_FORM_SUCCESS =
  'GET_APPOINTMENT_REQUEST_FORM_SUCCESS';
export const GET_APPOINTMENT_REQUEST_FORM_FAIL =
  'GET_APPOINTMENT_REQUEST_FORM_FAIL';

//---------------CREATE APPOINTMENT REQUEST-------------------
export const CREATE_APPOINTMENT_REQUEST_FORM_REQUEST =
  'CREATE_APPOINTMENT_REQUEST_FORM_REQUEST';
export const CREATE_APPOINTMENT_REQUEST_FORM_SUCCESS =
  'CREATE_APPOINTMENT_REQUEST_FORM_SUCCESS';
export const CREATE_APPOINTMENT_REQUEST_FORM_FAIL =
  'CREATE_APPOINTMENT_REQUEST_FORM_FAIL';

//---------------FETCH ALL APPOINTMENT REQUEST-------------------
export const GET_ALL_APPOINTMENT_REQUEST_DATA_REQUEST =
  'GET_ALL_APPOINTMENT_REQUEST_DATA_REQUEST';
export const GET_ALL_APPOINTMENT_REQUEST_DATA_SUCCESS =
  'GET_ALL_APPOINTMENT_REQUEST_DATA_SUCCESS';
export const GET_ALL_APPOINTMENT_REQUEST_DATA_FAIL =
  'GET_ALL_APPOINTMENT_REQUEST_DATA_FAIL';

//---------------GET APPOINTMENT REQUEST BY ID-------------------
export const GET_APPOINTMENT_REQUEST_BY_ID_REQUEST =
  'GET_APPOINTMENT_REQUEST_BY_ID_REQUEST';
export const GET_APPOINTMENT_REQUEST_BY_ID_SUCCESS =
  'GET_APPOINTMENT_REQUEST_BY_ID_SUCCESS';
export const GET_APPOINTMENT_REQUEST_BY_ID_FAIL =
  'GET_APPOINTMENT_REQUEST_BY_ID_FAIL';

//---------------DELETE APPOINTMENT REQUEST BY ID-------------------
export const DELETE_APPOINTMENT_REQUEST_BY_ID_REQUEST =
  'DELETE_APPOINTMENT_REQUEST_BY_ID_REQUEST';
export const DELETE_APPOINTMENT_REQUEST_BY_ID_SUCCESS =
  'DELETE_APPOINTMENT_REQUEST_BY_ID_SUCCESS';
export const DELETE_APPOINTMENT_REQUEST_BY_ID_FAIL =
  'DELETE_APPOINTMENT_REQUEST_BY_ID_FAIL';

//---------------CREATE APPOINTMENT REQUEST SCHEDULE-------------------
export const CREATE_APPOINTMENT_REQUEST_SCHEDULE_REQUEST =
  'CREATE_APPOINTMENT_REQUEST_SCHEDULE_REQUEST';
export const CREATE_APPOINTMENT_REQUEST_SCHEDULE_SUCCESS =
  'CREATE_APPOINTMENT_REQUEST_SCHEDULE_SUCCESS';
export const CREATE_APPOINTMENT_REQUEST_SCHEDULE_FAIL =
  'CREATE_APPOINTMENT_REQUEST_SCHEDULE_FAIL';

//---------------UPDATE GOOGLE LOGIN CREDENTIALS-------------------
export const UPDATE_GOOGLE_LOGIN_APPT_REQUEST =
  'UPDATE_GOOGLE_LOGIN_APPT_REQUEST';
export const UPDATE_GOOGLE_LOGIN_APPT_SUCCESS =
  'UPDATE_GOOGLE_LOGIN_APPT_SUCCESS';
export const UPDATE_GOOGLE_LOGIN_APPT_FAIL = 'UPDATE_GOOGLE_LOGIN_APPT_FAIL';

//---------------COMPLETE APPOINTMENT SCHEDULE REQUEST-------------------
export const COMPLETE_APPT_SCHEDULE_BY_ID_REQUEST =
  'COMPLETE_APPT_SCHEDULE_BY_ID_REQUEST';
export const COMPLETE_APPT_SCHEDULE_BY_ID_SUCCESS =
  'COMPLETE_APPT_SCHEDULE_BY_ID_SUCCESS';
export const COMPLETE_APPT_SCHEDULE_BY_ID_FAIL =
  'COMPLETE_APPT_SCHEDULE_BY_ID_FAIL';

//---------------GET APPOINTMENT REQUEST FROM-------------------------
export const GET_APPT_REQ_FORM_REQUEST = 'GET_APPT_REQ_FORM_REQUEST';
export const GET_APPT_REQ_FORM_SUCCESS = 'GET_APPT_REQ_FORM_SUCCESS';
export const GET_APPT_REQ_FORM_FAIL = 'GET_APPT_REQ_FORM_FAIL';

//---------------RESET APPOINTMENT REQUEST FROM-------------------------
export const RESET_REQ_APPT_REQUEST = 'RESET_REQ_APPT_REQUEST';
