import axios from 'axios';
import { GET } from 'redux/constants';
import * as services from 'services';

const BASE_URL = process.env.REACT_APP_API;
const CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

// Cache object to store responses
const cache = {};

// Headers
const getHeaders = (dispatch, onUploadProgress) => {
  if (dispatch && onUploadProgress) {
    return {
      'Content-Type': 'multipart/form-data',
      Authorization: services.getToken(),
    };
  }
  return {
    'Content-Type': 'application/json',
    Authorization: services.getToken(),
  };
};

// Generate cache key
const generateCacheKey = (method, url, data) => {
  return `${method}-${url}-${JSON.stringify(data)}`;
};

// Final method
const API_REQUEST = async (
  method,
  url,
  data,
  dispatch = null,
  onUploadProgress = null
) => {
  const headers = getHeaders(dispatch, onUploadProgress);
  const cacheKey = generateCacheKey(method, url, data);

  if (
    method === GET &&
    cache[cacheKey] &&
    Date.now() - cache[cacheKey].timestamp < CACHE_TIMEOUT
  ) {
    // Return cached response for GET request within cache timeout
    return cache[cacheKey].response;
  } else if (method !== GET) {
    // Clear cache for non-GET requests
    Object.keys(cache).forEach((key) => {
      delete cache[key];
    });
  }

  try {
    const response = await axios({
      headers,
      method,
      url: `${BASE_URL}/${url}`,
      data,
      onUploadProgress: (progressEvent) => {
        if (dispatch && onUploadProgress) {
          // const percentCompleted = Math.round(
          //   (progressEvent.loaded * 100) / progressEvent.total
          // );
          // dispatch(onUploadProgress(percentCompleted));
        }
      },
    });

    if (method === GET) {
      // Cache response for GET request
      cache[cacheKey] = {
        response,
        timestamp: Date.now(),
      };
    }

    return response;
  } catch (error) {
    throw error;
  }
};

export default API_REQUEST;
