import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CircularIndeterminate = (props) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress
        variant="indeterminate"
        sx={{ animationDuration: '0.5s !important' }}
        {...props}
      />
    </Box>
  );
};

export default CircularIndeterminate;
