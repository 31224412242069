import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import * as toastActions from 'redux/toast/actions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import 'react-toastify/dist/ReactToastify.css';

const Toast = () => {
  const dispatch = useDispatch();
  const { open, type, msg } = useSelector((state) => state.toast);

  const CloseButton = () => {
    return <CloseIcon sx={{ fontSize: 20 }} />;
  };

  useEffect(() => {
    if (open && type === 'success' && msg) {
      toast[type](msg, {
        className: 'toast-success',
        progressStyle: { background: '#4A9E5C' },
        icon: ({ theme, type }) => <CheckCircleIcon />,
      });
      dispatch(toastActions.resetToast());
    }

    if (type === 'error' && msg) {
      toast[type](msg, {
        className: 'toast-error',
        progressStyle: { background: '#E80606' },
        icon: ({ theme, type }) => <CancelOutlinedIcon />,
      });
      dispatch(toastActions.resetToast());
    }

    if (type === 'warning' && msg) {
      toast[type](msg, {
        className: 'toast-warning',
        progressStyle: { background: '#E80606' },
        icon: ({ theme, type }) => <CancelOutlinedIcon />,
      });
      dispatch(toastActions.resetToast());
    }

    if (type === 'info' && msg) {
      toast[type](msg, {
        className: 'toast-info',
        progressStyle: { background: '#6B77E1' },
        icon: ({ theme, type }) => <InfoOutlinedIcon />,
      });
      dispatch(toastActions.resetToast());
    }
  }, [open, type, msg, dispatch]);

  return <ToastContainer autoClose={2000} closeButton={CloseButton} />;
};

export default Toast;
