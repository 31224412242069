import React, { useState, Suspense, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import Spinner from 'components/Spinner';
import ThemeContext from 'themes/ThemeContext';
import { darkTheme, lightTheme } from 'themes';

import Pages from 'pages';
import store from 'redux/store';

import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const contextValue = useMemo(() => {
    return {
      darkMode,
      setDarkMode,
      lightTheme,
      darkTheme,
    };
  }, [darkMode]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <Provider store={store}>
          <Suspense
            fallback={
              <div className="container">
                <Spinner />
              </div>
            }
          >
            <Router>
              <Pages />
            </Router>
          </Suspense>
        </Provider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
