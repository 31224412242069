export const registerPayload = (data) => {
  return {
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
    password: data?.password,
    phone: data?.phone,
    source: data?.hearAbout,
  };
};

export const changePasswordPayload = (data) => {
  return {
    password: data?.currentPassword,
    newPassword: data?.newPassword,
  };
};

export const updateProfilePayload = (data) => {
  return {
    firstName: data?.firstName,
    lastName: data?.lastName,
    phone: data?.phone,
    source: data?.source,
  };
};
