import { GET, POST, PUT, DELETE, SHOW_TOAST } from 'redux/constants';
import API_REQUEST from 'redux/request';
import * as apis from 'redux/notifications/apis';
import * as constants from 'redux/notifications/constants';
import * as payloads from 'redux/notifications/payloads';
import * as messages from 'redux/toast/messages';

export const getAllUsersNotification = (type, page) => async (dispatch) => {
  try {
    dispatch({ type: constants.GET_ALL_USERS_NOTIFICATIONS_REQUEST });
    const res = await API_REQUEST(
      GET,
      `${apis.GET_ALL_USERS_NOTIFICATIONS_API}?type=${type}&pageSize=10&page=${page}`
    );
    const options = payloads.getAllUsersNotifications(res?.data);
    setTimeout(() => {
      dispatch({
        type: constants.GET_ALL_USERS_NOTIFICATIONS_SUCCESS,
        payload: options,
      });
    }, 1000);
  } catch (err) {
    console.error('getAllUsersNotification...', err);
    dispatch({ type: constants.GET_ALL_USERS_NOTIFICATIONS_FAIL });
  }
};

export const updatedMarkAsReadNotifications =
  (data, type) => async (dispatch) => {
    try {
      dispatch({ type: constants.UPDATE_USER_NOTIFICATION_REQUEST });
      const res = await API_REQUEST(
        PUT,
        `${apis.UPDATE_USER_NOTIFICATION_API}?type=${type}`,
        data
      );
      dispatch({
        type: constants.UPDATE_USER_NOTIFICATION_SUCCESS,
      });
      const msg = messages.getMessage(res?.data);
      dispatch({ type: SHOW_TOAST, payload: msg });
    } catch (err) {
      const error = messages.getMessage(err?.response?.data);
      console.error('MarkAsReadNotifications...', err);
      dispatch({ type: constants.UPDATE_USER_NOTIFICATION_SUCCESS });
      dispatch({ type: SHOW_TOAST, payload: error });
    }
  };

  export const updateNotifyPage = (page) => async (dispatch) => {
    dispatch({ type: constants.UPDATE_USER_NOTIFY_PAGE, payload: page });
  };
