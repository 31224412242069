export const GET_APP_SETTINGS_REQUEST = 'GET_APP_SETTINGS_REQUEST';
export const GET_APP_SETTINGS_SUCCESS = 'GET_APP_SETTINGS_SUCCESS';
export const GET_APP_SETTINGS_FAIL = 'GET_APP_SETTINGS_FAIL';

export const UPDATE_APP_SETTINGS_REQUEST = 'UPDATE_APP_SETTINGS_REQUEST';
export const UPDATE_APP_SETTINGS_SUCCESS = 'UPDATE_APP_SETTINGS_SUCCESS';
export const UPDATE_APP_SETTINGS_FAIL = 'UPDATE_APP_SETTINGS_FAIL';

//------------GET LEAD SOURCE DATA-------------------------------

export const GET_LEAD_SOURCE_REQUEST = 'GET_LEAD_SOURCE_REQUEST';
export const GET_LEAD_SOURCE_SUCCESS = 'GET_LEAD_SOURCE_SUCCESS';
export const GET_LEAD_SOURCE_FAIL = 'GET_LEAD_SOURCE_FAIL';

//-------------GET CLIENT TYPE DATA-------------------------------

export const GET_CLIENT_TYPE_REQUEST = 'GET_CLIENT_TYPE_REQUEST';
export const GET_CLIENT_TYPE_SUCCESS = 'GET_CLIENT_TYPE_SUCCESS';
export const GET_CLIENT_TYPE_FAIL = 'GET_CLIENT_TYPE_FAIL';
