import * as constants from 'redux/payments/constants';

const initialState = {
  refetch: false,
  loadingMethods: false,
  paymentMethods: null,
  addingMethod: false,
  refetchingStripe: false,
  updatingCard: false,
  deletingCard: false,
  updatingDefault: false,
};

export default function paymentSettingsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.GET_ALL_STRIPE_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        loadingMethods: true,
        refetchingStripe: false,
      };

    case constants.GET_ALL_STRIPE_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loadingMethods: false,
        paymentMethods: payload,
      };

    case constants.GET_ALL_STRIPE_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        loadingMethods: false,
      };

    case constants.ADD_STRIPE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        addingMethod: true,
        refetchingStripe: false,
      };

    case constants.ADD_STRIPE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        addingMethod: false,
        refetchingStripe: true,
      };

    case constants.ADD_STRIPE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        addingMethod: false,
        refetchingStripe: false,
      };

    case constants.UPDATE_STRIPE_PAYMENT_DEFAULT_CARD_REQUEST:
      return {
        ...state,
        updatingDefault: true,
        refetchingStripe: false,
      };

    case constants.UPDATE_STRIPE_PAYMENT_DEFAULT_CARD_SUCCESS:
      return {
        ...state,
        updatingDefault: false,
        refetchingStripe: true,
      };

    case constants.UPDATE_STRIPE_PAYMENT_DEFAULT_CARD_FAIL:
      return {
        ...state,
        updatingDefault: false,
        refetchingStripe: false,
      };

    case constants.UPDATE_STRIPE_PAYMENT_CARD_REQUEST:
      return {
        ...state,
        updatingCard: true,
        refetchingStripe: false,
      };

    case constants.UPDATE_STRIPE_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        updatingCard: false,
        refetchingStripe: true,
      };

    case constants.UPDATE_STRIPE_PAYMENT_CARD_FAIL:
      return {
        ...state,
        updatingCard: false,
        refetchingStripe: false,
      };

    case constants.DELETE_STRIPE_PAYMENT_CARD_REQUEST:
      return {
        ...state,
        deletingCard: true,
        refetchingStripe: false,
      };

    case constants.DELETE_STRIPE_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        deletingCard: false,
        refetchingStripe: true,
      };

    case constants.DELETE_STRIPE_PAYMENT_CARD_FAIL:
      return {
        ...state,
        deletingCard: false,
        refetchingStripe: false,
      };

    case constants.RESET_STRIPE_PAYMENT_REQUEST:
      return {
        ...state,
        refetch: false,
        refetchingStripe: false,
        paymentMethods: null,
      };

    default:
      return state;
  }
}
