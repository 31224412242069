import { createTheme } from '@mui/material/styles';

// const red = '#af0722';
// const lightBlue = '#1692bf';
// const darkBlue = '#00a8e5';

// const lightPurple = '#9B75F1';
// const darkPurple = '#7349e6';

const white = '#FFFFFF';
const gray = '#DDDDDD';
const black = '#000000';
const light = '#E4DAFF';
// const inputLight = '#CEF0FF';
// const inputBorder = '#C6EDFF';

// HOME OWNER THEME
const GreekSea = '#6B77E1';
const lightGray = '#4B4D5F';
const inputFieldBorder = '#E0E0E0';
// const background = 'EFF1F7'
const secondary = '#A5A6AF';
const lightGradient = 'linear-gradient(to right, #a6afff, #bca5ff)';
const darkGradient = 'linear-gradient(to right, #e65100c4, #e65100db)';
const darkBlue = '#576AA4';

// export const lightTheme = createTheme({
//   palette: {
//     type: 'light',
//     primary: {
//       main: darkPurple,
//     },
//     secondary: {
//       main: lightPurple,
//     },
//     gray: {
//       main: gray,
//     },
//     white: {
//       main: white,
//     },
//     black: {
//       main: black,
//     },
//     light: {
//       main: light,
//     },
//   },
//   typography: {
//     fontFamily: 'Poppins, sans-serif',
//   },
//   input: {
//     primary: {
//       main: inputLight,
//     },
//     border: {
//       main: inputBorder,
//     },
//   },
// });

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: black,
    },
    secondary: {
      main: white,
    },
    gray: {
      main: gray,
    },
    white: {
      main: white,
    },
    black: {
      main: black,
    },
    gradient: {
      main: darkGradient,
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
});

export const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: GreekSea,
    },
    secondary: {
      main: secondary,
    },
    gray: {
      main: lightGray,
    },
    white: {
      main: white,
    },
    black: {
      main: black,
    },
    light: {
      main: light,
    },
    gradient: {
      main: lightGradient,
    },
    darkBlue: {
      main: darkBlue,
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  input: {
    primary: {
      main: white,
    },
    border: {
      main: inputFieldBorder,
    },
  },
});
