import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PublicRoute = ({ isAuth, children }) => {
  const location = useLocation();
  if (isAuth) {
    return <Navigate to='/projects' state={{ from: location }} />;
  }

  // if (isAuth) {
  //   return <Navigate to='/home' state={{ from: location }} />;
  // }

  return <>{children}</>;
};

export default PublicRoute;
