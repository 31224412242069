import cookie from 'react-cookies';

export const setToken = (token) => {
  return cookie.save('vibras-token-homeOwner', token, { path: '/' });
};

export const getToken = () => {
  return cookie.load('vibras-token-homeOwner') || null;
};

export const removeToken = () => {
  return cookie.remove('vibras-token-homeOwner', { path: '/' });
};
