export const GET_PROPOSALS_REQUEST = 'GET_PROPOSALS_REQUEST';
export const GET_PROPOSALS_SUCCESS = 'GET_PROPOSALS_SUCCESS';
export const GET_PROPOSALS_FAIL = 'GET_PROPOSALS_FAIL';

export const CREATE_PROPOSAL_REQUEST = 'CREATE_PROPOSAL_REQUEST';
export const CREATE_PROPOSAL_SUCCESS = 'CREATE_PROPOSAL_SUCCESS';
export const CREATE_PROPOSAL_FAIL = 'CREATE_PROPOSAL_FAIL';

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL';

export const GET_PROPOSAL_ID_REQUEST = 'GET_PROPOSAL_ID_REQUEST';
export const GET_PROPOSAL_ID_SUCCESS = 'GET_PROPOSAL_ID_SUCCESS';
export const GET_PROPOSAL_ID_FAIL = 'GET_PROPOSAL_ID_REQUEST_FAIL';

export const GET_PROPOSAL_REQUEST = 'GET_PROPOSAL_REQUEST';
export const GET_PROPOSAL_SUCCESS = 'GET_PROPOSAL_SUCCESS';
export const GET_PROPOSAL_FAIL = 'GET_PROPOSAL_FAIL';

export const CREATE_PAGE_REQUEST = 'CREATE_PAGE_REQUEST';
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS';
export const CREATE_PAGE_FAIL = 'CREATE_PAGE_FAIL';

export const UPDATE_PAGE_ORDER_REQUEST = 'UPDATE_PAGE_ORDER_REQUEST';
export const UPDATE_PAGE_ORDER_SUCCESS = 'UPDATE_PAGE_ORDER_REQUEST_SUCCESS';
export const UPDATE_PAGE_ORDER_FAIL = 'UPDATE_PAGE_ORDER_FAIL';

export const UPDATE_PROPOSAL_REQUEST = 'UPDATE_PROPOSAL_REQUEST';
export const UPDATE_PROPOSAL_SUCCESS = 'UPDATE_PROPOSAL_SUCCESS';
export const UPDATE_PROPOSAL_FAIL = 'UPDATE_PROPOSAL_FAIL';

export const DELETE_PROPOSAL_REQUEST = 'DELETE_PROPOSAL_REQUEST';
export const DELETE_PROPOSAL_SUCCESS = 'DELETE_PROPOSAL_SUCCESS';
export const DELETE_PROPOSAL_FAIL = 'DELETE_PROPOSAL_FAIL';

export const UPDATE_ACTIVE_REQUEST = 'UPDATE_ACTIVE_REQUEST';
export const UPDATE_ACTIVE_SUCCESS = 'UPDATE_ACTIVE_SUCCESS';
export const UPDATE_ACTIVE_FAIL = 'UPDATE_ACTIVE_FAIL';

export const UPDATE_SIDENAV_REQUEST = 'UPDATE_SIDENAV_REQUEST';

export const UPLOAD_TITLE_IMAGE_REQUEST = 'UPLOAD_TITLE_IMAGE_REQUEST';
export const UPLOAD_TITLE_IMAGE_SUCCESS = 'UPLOAD_TITLE_IMAGE_SUCCESS';
export const UPLOAD_TITLE_IMAGE_FAIL = 'UPLOAD_TITLE_IMAGE_FAIL';

export const TITLE_PROGRESS = 'TITLE_PROGRESS';
export const RESET_TITLE_PROGRESS = 'RESET_TITLE_PROGRESS';

export const UPLOAD_TITLE_LOGO_REQUEST = 'UPLOAD_TITLE_LOGO_REQUEST';
export const UPLOAD_TITLE_LOGO_SUCCESS = 'UPLOAD_TITLE_LOGO_SUCCESS';
export const UPLOAD_TITLE_LOGO_FAIL = 'UPLOAD_TITLE_LOGO_FAIL';

export const TITLE_LOGO_PROGRESS = 'TITLE_LOGO_PROGRESS';
export const RESET_TITLE_LOGO_PROGRESS = 'RESET_TITLE_LOGO_PROGRESS';

export const SAVE_PAGE_TEMPLATE_REQUEST = 'SAVE_PAGE_TEMPLATE_REQUEST';
export const SAVE_PAGE_TEMPLATE_SUCCESS = 'SAVE_PAGE_TEMPLATE_SUCCESS';
export const SAVE_PAGE_TEMPLATE_FAIL = 'SAVE_PAGE_TEMPLATE_FAIL';

export const SAVE_PROPOSAL_TEMPLATE_REQUEST = 'SAVE_PROPOSAL_TEMPLATE_REQUEST';
export const SAVE_PROPOSAL_TEMPLATE_SUCCESS = 'SAVE_PROPOSAL_TEMPLATE_SUCCESS';
export const SAVE_PROPOSAL_TEMPLATE_FAIL = 'SAVE_PROPOSAL_TEMPLATE_FAIL';

export const SAVE_AS_TEMPLATE_PROPOSAL_REQUEST =
  'SAVE_AS_TEMPLATE_PROPOSAL_REQUEST';
export const SAVE_AS_TEMPLATE_PROPOSAL_SUCCESS =
  'SAVE_AS_TEMPLATE_PROPOSAL_SUCCESS';
export const SAVE_AS_TEMPLATE_PROPOSAL_FAIL = 'SAVE_AS_TEMPLATE_PROPOSAL_FAIL';

export const SAVE_AS_TEMPLATE_TITLE_REQUEST = 'SAVE_AS_TEMPLATE_TITLE_REQUEST';
export const SAVE_AS_TEMPLATE_TITLE_SUCCESS = 'SAVE_AS_TEMPLATE_TITLE_SUCCESS';
export const SAVE_AS_TEMPLATE_TITLE_FAIL = 'SAVE_AS_TEMPLATE_TITLE_FAIL';

export const SEND_PROPOSAL_REQUEST = 'SEND_PROPOSAL_REQUEST';
export const SEND_PROPOSAL_SUCCESS = 'SEND_PROPOSAL_SUCCESS';
export const SEND_PROPOSAL_FAIL = 'SEND_PROPOSAL_FAIL';

export const GENERATE_PDF_REQUEST = 'GENERATE_PDF_REQUEST';
export const GENERATE_PDF_SUCCESS = 'GENERATE_PDF_SUCCESS';
export const GENERATE_PDF_FAIL = 'GENERATE_PDF_FAIL';

export const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAIL = 'DELETE_PAGE_FAIL';

export const GENERATE_PROPOSAL_PDF_REQUEST = 'GENERATE_PROPOSAL_PDF_REQUEST';
export const GENERATE_PROPOSAL_PDF_SUCCESS = 'GENERATE_PROPOSAL_PDF_SUCCESS';
export const GENERATE_PROPOSAL_PDF_FAIL = 'GENERATE_PROPOSAL_PDF_FAIL';

export const GET_PROPOSAL_TEMPLATE_REQUEST = 'GET_PROPOSAL_TEMPLATE_REQUEST';
export const GET_PROPOSAL_TEMPLATE_SUCCESS = 'GET_PROPOSAL_TEMPLATE_SUCCESS';
export const GET_PROPOSAL_TEMPLATE_FAIL = 'GET_PROPOSAL_TEMPLATE_FAIL';

export const UPDATE_ACCEPT_PROPOSAL_REQUEST = 'UPDATE_ACCEPT_PROPOSAL_REQUEST';
export const UPDATE_ACCEPT_PROPOSAL_SUCCESS = 'UPDATE_ACCEPT_PROPOSAL_SUCCESS';
export const UPDATE_ACCEPT_PROPOSAL_FAIL = 'UPDATE_ACCEPT_PROPOSAL_FAIL';

export const UPDATE_REJECT_PROPOSAL_REQUEST = 'UPDATE_REJECT_PROPOSAL_REQUEST';
export const UPDATE_REJECT_PROPOSAL_SUCCESS = 'UPDATE_REJECT_PROPOSAL_SUCCESS';
export const UPDATE_REJECT_PROPOSAL_FAIL = 'UPDATE_REJECT_PROPOSAL_FAIL';

export const GET_UPDATED_PROPOSAL = 'GET_UPDATED_PROPOSAL';

export const UPLOAD_PROPOSAL_TITLE_IMAGE_REQUEST =
  'UPLOAD_PROPOSAL_TITLE_IMAGE_REQUEST';
export const UPLOAD_PROPOSAL_TITLE_IMAGE_SUCCESS =
  'UPLOAD_PROPOSAL_TITLE_IMAGE_SUCCESS';
export const UPLOAD_PROPOSAL_TITLE_IMAGE_FAIL =
  'UPLOAD_PROPOSAL_TITLE_IMAGE_FAIL';

export const PROPOSAL_TITLE_IMAGE_PROGRESS = 'PROPOSAL_TITLE_IMAGE_PROGRESS';
export const RESET_PROPOSAL_TITLE_IMAGE_PROGRESS =
  'RESET_PROPOSAL_TITLE_IMAGE_PROGRESS';

export const UPLOAD_PROPOSAL_TITLE_LOGO_REQUEST =
  'UPLOAD_PROPOSAL_TITLE_LOGO_REQUEST';
export const UPLOAD_PROPOSAL_TITLE_LOGO_SUCCESS =
  'UPLOAD_PROPOSAL_TITLE_LOGO_SUCCESS';
export const UPLOAD_PROPOSAL_TITLE_LOGO_FAIL =
  'UPLOAD_PROPOSAL_TITLE_LOGO_FAIL';

export const PROPOSAL_TITLE_LOGO_PROGRESS = 'PROPOSAL_TITLE_LOGO_PROGRESS';
export const RESET_PROPOSAL_TITLE_LOGO_PROGRESS =
  'RESET_PROPOSAL_TITLE_LOGO_PROGRESS';

export const RESET_PROPOSALS_REFETCH = 'RESET_PROPOSALS_REFETCH';

export const RESET_DELETE_PROPOSAL = 'RESET_DELETE_PROPOSAL';

export const UPDATE_PROPOSALS_PAGE = 'UPDATE_PROPOSALS_PAGE';

export const CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_FAIL = 'CREATE_NOTE_FAIL';

export const UPLOAD_ATTACHMENT_REQUEST = 'UPLOAD_ATTACHMENT_REQUEST';
export const UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS';
export const UPLOAD_ATTACHMENT_FAIL = 'UPLOAD_ATTACHMENT_FAIL';
export const ATTACHMENT_PROGRESS = 'ATTACHMENT_PROGRESS';
export const RESET_ATTACHMENT_PROGRESS = 'RESET_ATTACHMENT_PROGRESS';

export const GET_NOTES_REQUEST = 'GET_NOTES_REQUEST';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_FAIL = 'GET_NOTES_FAIL';

export const DELETE_NOTE_REQUEST = 'DELETE_NOTE_REQUEST';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAIL = 'DELETE_NOTE_FAIL';

export const PROPOSAL_INCREASE_COUNT_REQUEST =
  'PROPOSAL_INCREASE_COUNT_REQUEST';
export const PROPOSAL_INCREASE_COUNT_SUCCESS =
  'PROPOSAL_INCREASE_COUNT_SUCCESS';
export const PROPOSAL_INCREASE_COUNT_FAIL = 'PROPOSAL_INCREASE_COUNT_FAIL';

//--------------------------------QUOTE_DETAIL-------------------------------------

export const GET_QUOTE_DETAIL_SETTINGS = 'GET_QUOTE_DETAIL_SETTINGS';
export const UPDATE_PROPOSAL_FOR_QUOTE_DETAIL_ARRAY =
  'UPDATE_PROPOSAL_FOR_QUOTE_DETAIL_ARRAY';

export const QUOTE_DETAIL_GET_PAGE_SETTINGS_REQUEST =
  'QUOTE_DETAIL_GET_PAGE_SETTINGS_REQUEST';
export const QUOTE_DETAIL_GET_PAGE_SETTINGS_SUCCESS =
  'QUOTE_DETAIL_GET_PAGE_SETTINGS_SUCCESS';
export const QUOTE_DETAIL_GET_PAGE_SETTINGS_FAIL =
  'QUOTE_DETAIL_GET_PAGE_SETTINGS_FAIL';

export const QUOTE_DETAIL_UPDATE_PAGE_SETTINGS_REQUEST =
  'QUOTE_DETAIL_UPDATE_PAGE_SETTINGS_REQUEST';
export const QUOTE_DETAIL_UPDATE_PAGE_SETTINGS_SUCCESS =
  'QUOTE_DETAIL_UPDATE_PAGE_SETTINGS_SUCCESS';
export const QUOTE_DETAIL_UPDATE_PAGE_SETTINGS_FAIL =
  'QUOTE_DETAIL_UPDATE_PAGE_SETTINGS_FAIL';

export const QUOTE_DETAIL_CREATE_TABS_REQUEST =
  'QUOTE_DETAIL_CREATE_TABS_REQUEST';
export const QUOTE_DETAIL_CREATE_TABS_SUCCESS =
  'QUOTE_DETAIL_CREATE_TABS_SUCCESS';
export const QUOTE_DETAIL_CREATE_TABS_FAIL = 'QUOTE_DETAIL_CREATE_TABS_FAIL';

export const QUOTE_DETAIL_TABS_DELETE_REQUEST =
  'QUOTE_DETAIL_TABS_DELETE_REQUEST';
export const QUOTE_DETAIL_TABS_DELETE_SUCCESS =
  'QUOTE_DETAIL_TABS_DELETE_SUCCESS';
export const QUOTE_DETAIL_TABS_DELETE_FAIL = 'QUOTE_DETAIL_TABS_DELETE_FAIL';

export const QUOTE_DETAIL_CREATE_SECTION_REQUEST =
  'QUOTE_DETAIL_CREATE_SECTION_REQUEST';
export const QUOTE_DETAIL_CREATE_SECTION_SUCCESS =
  'QUOTE_DETAIL_CREATE_SECTION_SUCCESS';
export const QUOTE_DETAIL_CREATE_SECTION_FAIL =
  'QUOTE_DETAIL_CREATE_SECTION_FAIL';

export const QUOTE_DETAIL_DELETE_SECTION_REQUEST =
  'QUOTE_DETAIL_DELETE_SECTION_REQUEST';
export const QUOTE_DETAIL_DELETE_SECTION_SUCCESS =
  'QUOTE_DETAIL_DELETE_SECTION_SUCCESS';
export const QUOTE_DETAIL_DELETE_SECTION_FAIL =
  'QUOTE_DETAIL_DELETE_SECTION_FAIL';

export const QUOTE_DETAIL_CREATE_ITEM_REQUEST =
  'QUOTE_DETAIL_CREATE_ITEM_REQUEST';
export const QUOTE_DETAIL_CREATE_ITEM_SUCCESS =
  'QUOTE_DETAIL_CREATE_ITEM_SUCCESS';
export const QUOTE_DETAIL_CREATE_ITEM_FAIL = 'QUOTE_DETAIL_CREATE_ITEM_FAIL';

export const QUOTE_DETAIL_DELETE_ITEM_REQUEST =
  'QUOTE_DETAIL_DELETE_ITEM_REQUEST';
export const QUOTE_DETAIL_DELETE_ITEM_SUCCESS =
  'QUOTE_DETAIL_DELETE_ITEM_SUCCESS';
export const QUOTE_DETAIL_DELETE_ITEM_FAIL = 'QUOTE_DETAIL_DELETE_ITEM_FAIL';

export const QUOTE_DETAIL_CREATE_TAX_RATE_REQUEST =
  'QUOTE_DETAIL_CREATE_TAX_RATE_REQUEST';
export const QUOTE_DETAIL_CREATE_TAX_RATE_SUCCESS =
  'QUOTE_DETAIL_CREATE_TAX_RATE_SUCCESS';
export const QUOTE_DETAIL_CREATE_TAX_RATE_FAIL =
  'QUOTE_DETAIL_CREATE_TAX_RATE_FAIL';

export const QUOTE_DETAIL_GET_TAX_ALL_REQUEST =
  'QUOTE_DETAIL_GET_TAX_ALL_REQUEST';
export const QUOTE_DETAIL_GET_TAX_ALL_SUCCESS =
  'QUOTE_DETAIL_GET_TAX_ALL_SUCCESS';
export const QUOTE_DETAIL_GET_TAX_ALL_FAIL = 'QUOTE_DETAIL_GET_TAX_ALL_FAIL';

//--------------------------------CUSTOM PAGE------------------------------------
export const DELETE_CUSTOM_PAGE_REQUEST = 'DELETE_CUSTOM_PAGE_REQUEST';
export const DELETE_CUSTOM_PAGE_SUCCESS = 'DELETE_CUSTOM_PAGE_SUCCESS';
export const DELETE_CUSTOM_PAGE_FAIL = 'DELETE_CUSTOM_PAGE_FAIL';

export const CREATE_CUSTOM_SECTION_REQUEST = 'CREATE_CUSTOM_SECTION_REQUEST';
export const CREATE_CUSTOM_SECTION_SUCCESS = 'CREATE_CUSTOM_SECTION_SUCCESS';
export const CREATE_CUSTOM_SECTION_FAIL = 'CREATE_CUSTOM_SECTION_FAIL';

export const DELETE_CUSTOM_PAGE_SECTION_REQUEST = 'DELETE_CUSTOM_PAGE_SECTION';
export const DELETE_CUSTOM_PAGE_SECTION_SUCCESS =
  'DELETE_CUSTOM_PAGE_SECTION_SUCCESS';
export const DELETE_CUSTOM_PAGE_SECTION_FAIL =
  'DELETE_CUSTOM_PAGE_SECTION_FAIL';

export const DELETE_CUSTOM_PAGE_ITEM_REQUEST =
  'DELETE_CUSTOM_PAGE_ITEM_REQUEST';
export const DELETE_CUSTOM_PAGE_ITEM_SUCCESS =
  'DELETE_CUSTOM_PAGE_ITEM_SUCCESS';
export const DELETE_CUSTOM_PAGE_ITEM_FAIL = 'DELETE_CUSTOM_PAGE_ITEM_FAIL';

export const CREATE_CUSTOM_SECTION_ITEM_REQUEST =
  'CREATE_CUSTOM_SECTION_ITEM_REQUEST';
export const CREATE_CUSTOM_SECTION_ITEM_SUCCESS =
  'CREATE_CUSTOM_SECTION_ITEM_SUCCESS';
export const CREATE_CUSTOM_SECTION_ITEM_FAIL =
  'CREATE_CUSTOM_SECTION_ITEM_FAIL';

export const CUSTOM_IMAGE_PROGRESS = 'CUSTOM_IMAGE_PROGRESS';
export const RESET_CUSTOM_IMAGE_PROGRESS = 'RESET_CUSTOM_IMAGE_PROGRESS';

export const UPLOAD_CUSTOM_IMAGE_REQUEST = 'UPLOAD_CUSTOM_IMAGE_REQUEST';
export const UPLOAD_CUSTOM_IMAGE_SUCCESS = 'UPLOAD_CUSTOM_IMAGE_SUCCESS';
export const UPLOAD_CUSTOM_IMAGE_FAIL = 'UPLOAD_CUSTOM_IMAGE_FAIL';

export const GET_UPDATED_NEW_PROPOSAL = 'GET_UPDATED_NEW_PROPOSAL';

export const DELETE_CUSTOM_PAGE_SECTION_IMG_REQUEST =
  'DELETE_CUSTOM_PAGE_SECTION_IMG_REQUEST';
export const DELETE_CUSTOM_PAGE_SECTION_IMG_SUCCESS =
  'DELETE_CUSTOM_PAGE_SECTION_IMG_SUCCESS';
export const DELETE_CUSTOM_PAGE_SECTION_IMG_FAIL =
  'DELETE_CUSTOM_PAGE_SECTION_IMG_FAIL';

//--------------------------------ACCEPT PROPOSAL -----------------------------------
export const PROPOSAL_MODIFY_REQUEST = 'PROPOSAL_MODIFY_REQUEST';
export const PROPOSAL_MODIFY_SUCCESS = 'PROPOSAL_MODIFY_SUCCESS';
export const PROPOSAL_MODIFY_FAIL = 'PROPOSAL_MODIFY_FAIL';

export const GET_CLIENT_PROPOSAL_LIST_REQUEST =
  'GET_CLIENT_PROPOSAL_LIST_REQUEST';
export const GET_CLIENT_PROPOSAL_LIST_SUCCESS =
  'GET_CLIENT_PROPOSAL_LIST_SUCCESS';
export const GET_CLIENT_PROPOSAL_LIST_FAIL = 'GET_CLIENT_PROPOSAL_LIST_FAIL';

export const ACCEPT_PROPOSAL_VIEW_UPDATE_REQUEST =
  'ACCEPT_PROPOSAL_VIEW_UPDATE_REQUEST';
export const ACCEPT_PROPOSAL_VIEW_UPDATE_SUCCESS =
  'ACCEPT_PROPOSAL_VIEW_UPDATE_SUCCESS';
export const ACCEPT_PROPOSAL_VIEW_UPDATE_FAIL =
  'ACCEPT_PROPOSAL_VIEW_UPDATE_FAIL';

//-----------------------UPDATE CUSTOMER PROPOSAL----------------------
export const UPDATE_CUSTOMER_PROPOSAL_REQUEST =
  'UPDATE_CUSTOMER_PROPOSAL_REQUEST';
export const UPDATE_CUSTOMER_PROPOSAL_SUCCESS =
  'UPDATE_CUSTOMER_PROPOSAL_SUCCESS';
export const UPDATE_CUSTOMER_PROPOSAL_FAIL = 'UPDATE_CUSTOMER_PROPOSAL_FAIL';

//-----------------------GET CUSTOMER PORTAL PROPOSAL BY ID----------------------
export const GET_CUSTOMER_PROPOSAL_BY_ID_REQUEST =
  'GET_CUSTOMER_PROPOSAL_BY_ID_REQUEST';
export const GET_CUSTOMER_PROPOSAL_BY_ID_SUCCESS =
  'GET_CUSTOMER_PROPOSAL_BY_ID_SUCCESS';
export const GET_CUSTOMER_PROPOSAL_BY_ID_FAIL =
  'GET_CUSTOMER_PROPOSAL_BY_ID_FAIL';

//-----------------------DELETE CUSTOMER PROPOSAL PRICE----------------------
export const DELETE_CUSTOMER_PROPOSAL_PRICE_REQUEST =
  'DELETE_CUSTOMER_PROPOSAL_PRICE_REQUEST';
export const DELETE_CUSTOMER_PROPOSAL_PRICE_SUCCESS =
  'DELETE_CUSTOMER_PROPOSAL_PRICE_SUCCESS';
export const DELETE_CUSTOMER_PROPOSAL_PRICE_FAIL =
  'DELETE_CUSTOMER_PROPOSAL_PRICE_FAIL';


  //------------------------GET CUSTOMER PORTAL ALL PROPOSAL----------------------
export const GET_CUSTOMER_PORTAL_ALL_PROPOSAL_REQUEST =
'GET_CUSTOMER_PORTAL_ALL_PROPOSAL_REQUEST';
export const GET_CUSTOMER_PORTAL_ALL_PROPOSAL_SUCCESS =
'GET_CUSTOMER_PORTAL_ALL_PROPOSAL_SUCCESS';
export const GET_CUSTOMER_PORTAL_ALL_PROPOSAL_FAIL =
'GET_CUSTOMER_PORTAL_ALL_PROPOSAL_FAIL';