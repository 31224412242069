export const GET_REGISTER_HEAR_ABOUT_US_REQUEST =
  'GET_REGISTER_HEAR_ABOUT_US_REQUEST';
export const GET_REGISTER_HEAR_ABOUT_US_SUCCESS =
  'GET_REGISTER_HEAR_ABOUT_US_SUCCESS';
export const GET_REGISTER_HEAR_ABOUT_US_FAIL =
  'GET_REGISTER_HEAR_ABOUT_US_FAIL';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const ACCOUNT_VERIFY_REQUEST = 'ACCOUNT_VERIFY_REQUEST';
export const ACCOUNT_VERIFY_SUCCESS = 'ACCOUNT_VERIFY_SUCCESS';
export const ACCOUNT_VERIFY_FAIL = 'ACCOUNT_VERIFY_FAIL';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

//--------------CHANGE USER PASSWORD-----------------

export const CHANGE_USER_PASSWORD_REQUEST = 'CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAIL = 'CHANGE_USER_PASSWORD_FAIL';

//--------------UPDATE USER PROFILE-----------------

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';

export const RESET_AUTH_REFETCH = 'RESET_AUTH_REFETCH';
