import { GET, POST, SHOW_TOAST, PUT } from 'redux/constants';
import API_REQUEST from 'redux/request';

import * as constants from 'redux/HomeOwner/auth/constants';
import * as payloads from 'redux/HomeOwner/auth/payloads';
import * as apis from 'redux/HomeOwner/auth/apis';
import * as messages from 'redux/toast/messages';

import * as services from 'services';

export const getHearAboutUs = () => async (dispatch) => {
  try {
    dispatch({ type: constants.GET_REGISTER_HEAR_ABOUT_US_REQUEST });
    const res = await API_REQUEST(GET, apis.GET_HEAR_ABOUT_US_API);
    dispatch({
      type: constants.GET_REGISTER_HEAR_ABOUT_US_SUCCESS,
      payload: res?.data?.data,
    });
  } catch (err) {
    console.error('getHearAboutUs...', err);
    dispatch({ type: constants.GET_REGISTER_HEAR_ABOUT_US_FAIL });
  }
};

export const register = (payload) => async (dispatch) => {
  try {
    dispatch({ type: constants.REGISTER_REQUEST });
    const data = payloads.registerPayload(payload);
    const res1 = await API_REQUEST(POST, apis.REGISTER_API, data);
    services.setToken(res1?.data?.data?.accessToken);
    const msg = messages.getMessage(res1.data);
    dispatch({ type: SHOW_TOAST, payload: msg });
    dispatch({ type: constants.REGISTER_SUCCESS });
  } catch (err) {
    console.error('register...', err);
    const error = messages.getMessage(err?.response?.data);
    dispatch({ type: constants.REGISTER_FAIL });
    dispatch({ type: SHOW_TOAST, payload: error });
  }
};

export const accountVerify = (id) => async (dispatch) => {
  try {
    dispatch({ type: constants.ACCOUNT_VERIFY_REQUEST });
    const res = await API_REQUEST(
      GET,
      `${apis.ACCOUNT_VERIFY_API}?ownerId=${id}`
    );
    if (res && res.data) {
      dispatch({ type: constants.ACCOUNT_VERIFY_SUCCESS, payload: true });
    } else dispatch({ type: constants.ACCOUNT_VERIFY_FAIL, payload: false });
  } catch (err) {
    console.error('accountVerify...', err);
    dispatch({ type: constants.ACCOUNT_VERIFY_FAIL });
  }
};

export const login = (data) => async (dispatch) => {
  try {
    dispatch({ type: constants.LOGIN_REQUEST });
    const res1 = await API_REQUEST(POST, apis.LOGIN_API, data);
    services.setToken(res1?.data?.data?.accessToken);
    const res2 = await API_REQUEST(GET, apis.GET_USER_API);
    if (res2?.data?.data) {
      dispatch({
        type: constants.LOGIN_SUCCESS,
        payload: res2?.data?.data,
      });
    } else {
      dispatch({ type: constants.LOGIN_FAIL });
    }
  } catch (err) {
    console.error('login...', err);
    const error = messages.getMessage(err?.response?.data);
    dispatch({ type: constants.LOGIN_FAIL });
    dispatch({ type: SHOW_TOAST, payload: error });
  }
};

export const getUser = () => async (dispatch) => {
  try {
    if (services.getToken()) {
      dispatch({ type: constants.GET_USER_REQUEST });
      const res = await API_REQUEST(GET, apis.GET_USER_API);
      if (res?.data?.data)
        dispatch({
          type: constants.GET_USER_SUCCESS,
          payload: res?.data?.data,
        });
      else dispatch({ type: constants.GET_USER_FAIL });
    } else {
      dispatch({ type: constants.GET_USER_FAIL });
    }
  } catch (err) {
    console.error('getUser...', err);
    dispatch({ type: constants.GET_USER_FAIL });
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  try {
    dispatch({ type: constants.FORGET_PASSWORD_REQUEST });
    const res = await API_REQUEST(POST, apis.FORGET_PASSWORD_API, data);
    if (res && res.data) {
      const msg = messages.getMessage(res.data);
      dispatch({ type: constants.FORGET_PASSWORD_SUCCESS });
      dispatch({ type: SHOW_TOAST, payload: msg });
    } else dispatch({ type: constants.FORGET_PASSWORD_FAIL });
  } catch (err) {
    console.error('forgotPassword...', err);
    const error = messages.getMessage(err?.response?.data);
    dispatch({ type: constants.FORGET_PASSWORD_FAIL });
    dispatch({ type: SHOW_TOAST, payload: error });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: constants.LOGOUT_REQUEST });
    dispatch({ type: constants.LOGOUT_SUCCESS });
    services.removeToken();
  } catch (err) {
    console.error('logout...', err);
    dispatch({ type: constants.LOGOUT_FAIL });
  }
};

export const changePassword = (payload) => async (dispatch) => {
  try {
    dispatch({ type: constants.CHANGE_USER_PASSWORD_REQUEST });
    const data = payloads.changePasswordPayload(payload);
    const res = await API_REQUEST(POST, apis.CHANGE_USER_PASSWORD_API, data);
    if (res && res.data) {
      const msg = messages.getMessage(res.data);
      dispatch({ type: constants.CHANGE_USER_PASSWORD_SUCCESS });
      dispatch({ type: SHOW_TOAST, payload: msg });
    } else dispatch({ type: constants.CHANGE_USER_PASSWORD_FAIL });
  } catch (err) {
    console.error('changeUserPassword...', err);
    const error = messages.getMessage(err?.response?.data);
    dispatch({ type: constants.CHANGE_USER_PASSWORD_FAIL });
    dispatch({ type: SHOW_TOAST, payload: error });
  }
};

export const updateUserProfile = (payload) => async (dispatch) => {
  try {
    dispatch({ type: constants.UPDATE_USER_PROFILE_REQUEST });
    const data = payloads.updateProfilePayload(payload);
    const res = await API_REQUEST(PUT, apis.UPDATE_USER_PROFILE_API, data);
    const msg = messages.getMessage(res.data);
    dispatch({
      type: constants.UPDATE_USER_PROFILE_SUCCESS,
      payload: res?.data?.data,
    });
    dispatch({ type: SHOW_TOAST, payload: msg });
  } catch (err) {
    console.error('updateUserProfile...', err);
    const error = messages.getMessage(err?.response?.data);
    dispatch({ type: constants.UPDATE_USER_PROFILE_FAIL });
    dispatch({ type: SHOW_TOAST, payload: error });
  }
};

export const resetRefetch = () => async (dispatch) => {
  dispatch({ type: constants.RESET_AUTH_REFETCH });
};
