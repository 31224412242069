import * as constants from 'redux/HomeOwner/myProjects/constants';

const initialState = {
    gettingAll: false,
    getAllProjects: [],
    creating: false,
    getCreateProjectRes: null,
    refetch: false,
    deletingProject: false,
    updateProject: false,
    fetchDeleting: false,
    getting: false,
    getProject: null,
    progress: 0,
    uploadingProjectImage: false,
    projectImg: null,
    deletingImage: false,
    inviting: false,
    inviteContractor: null,
    fetchInvite: false,
    updating: false,
    updateInvitedContractor: [],
    gettingContractor: false,
    getInvitedContractor: null,
    allContractor: false,
    allContractorList: [],
}

export default function projectReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case constants.GET_ALL_PROJECT_REQUEST:
            return {
                ...state,
                gettingAll: true,
            };

        case constants.GET_ALL_PROJECT_SUCCESS:
            return {
                ...state,
                gettingAll: false,
                getAllProjects: payload,
                fetchDeleting: false,
                fetchInvite: false,
            };

        case constants.GET_ALL_PROJECT_FAIL:
            return {
                ...state,
                gettingAll: false,
            };

        case constants.CREATE_PROJECT_REQUEST:
            return {
                ...state,
                creating: true,
            };

        case constants.CREATE_PROJECT_SUCCESS: {
            const { getAllProjects } = state;
            const createProject = [...getAllProjects.rows, payload];
            return {
                ...state,
                creating: false,
                refetch: true,
                getAllProjects: createProject,
                getCreateProjectRes: payload,
            };
        }

        case constants.CREATE_PROJECT_FAIL:
            return {
                ...state,
                creating: false,
            };

        case constants.DELETE_PROJECT_REQUEST:
            return {
                ...state,
                deletingProject: true,
                fetchDeleting: false,
            };

        case constants.UPDATE_PROJECT_REQUEST:
            return {
                ...state,
                updateProject: true,
            };

        case constants.UPDATE_PROJECT_SUCCESS:
        case constants.UPDATE_PROJECT_FAIL:
            return {
                ...state,
                updateProject: false,
            };

        case constants.DELETE_PROJECT_SUCCESS: {
            const copyGetAllProject = [...state.getAllProjects.rows];
            const _getAllProject = copyGetAllProject.filter(item => item.id !== payload);
            return {
                ...state,
                deletingProject: false,
                fetchDeleting: true,
                getAllProjects: {
                    ...state.getAllProjects,
                    rows: _getAllProject,
                },
            }
        }

        case constants.DELETE_PROJECT_FAIL:
            return {
                ...state,
                deletingProject: false,
                fetchDeleting: false,
            };

        case constants.GET_PROJECT_BY_PROJECT_ID_REQUEST:
            return {
                ...state,
                getting: true,
            }

        case constants.GET_PROJECT_BY_PROJECT_ID_SUCCESS:
            return {
                ...state,
                getting: false,
                getProject: payload,
            }

        case constants.GET_PROJECT_BY_PROJECT_ID_FAIL:
            return {
                ...state,
                getting: false,
            }

        case constants.PROJECT_IMG_PROGRESS:
            return {
                ...state,
                progress: payload,
            };

        case constants.RESET_IMG_PROGRESS:
            return {
                ...state,
                progress: 0,
            };

        case constants.UPLOAD_PROJECT_IMG_REQUEST:
            return {
                ...state,
                uploadingProjectImage: true,
            };

        case constants.UPLOAD_PROJECT_IMG_SUCCESS:
            return {
                ...state,
                uploadingProjectImage: false,
                projectImg: payload
            };

        case constants.UPLOAD_PROJECT_IMG_FAIL:
            return {
                ...state,
                uploadingProjectImage: false,
            };

        case constants.DELETE_PROJECT_IMG_REQUEST:
            return {
                ...state,
                deletingImage: true,
            };

        case constants.DELETE_PROJECT_IMG_SUCCESS:
        case constants.DELETE_PROJECT_IMG_FAIL:
            return {
                ...state,
                deletingImage: false,
            };

        case constants.GET_INVITED_CONTRACTOR_REQUEST:
            return {
                ...state,
                gettingContractor: true,
            }

        case constants.GET_INVITED_CONTRACTOR_SUCCESS:
            return {
                ...state,
                fetchInvite: false,
                gettingContractor: false,
                updating: false,
                getInvitedContractor: payload,
            }

        case constants.GET_INVITED_CONTRACTOR_FAIL:
            return {
                ...state,
                gettingContractor: false,
            }

        case constants.SEND_INVITE_TO_CONTRACTOR_REQUEST:
            return {
                ...state,
                inviting: true,
                fetchInvite: false,
            }

        case constants.SEND_INVITE_TO_CONTRACTOR_SUCCESS:
            return {
                ...state,
                inviting: false,
                fetchInvite: true,
                inviteContractor: payload,
                getInvitedContractor: {
                    ...state.getInvitedContractor,
                    result: payload.invites,
                }
            }

        case constants.SEND_INVITE_TO_CONTRACTOR_FAIL:
            return {
                ...state,
                inviting: false,
                fetchInvite: false,
            }

        case constants.UPDATE_INVITED_CONTRACTOR_FAIL:
        case constants.UPDATE_INVITED_CONTRACTOR_REQUEST:
            return {
                ...state,
                updating: false,
            }

        case constants.UPDATE_INVITED_CONTRACTOR_SUCCESS:
            return {
                ...state,
                updating: true,
            }

        case constants.GET_ALL_CONTRACTOR_REQUEST:
            return {
                ...state,
                allContractor: false,
            }

        case constants.GET_ALL_CONTRACTOR_SUCCESS:
            return {
                ...state,
                allContractorList: payload,
            }

        case constants.GET_ALL_CONTRACTOR_FAIL:
            return {
                ...state,
                allContractor: false,
            }

        case constants.RESET_REFETCH:
            return {
                ...state,
                refetch: false,
            }

        default:
            return state;
    }
}