import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Avatar from 'components/Avatar';

const AccountAvatar = ({ name, ...props }) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar name={name} {...props} />
      <Box ml={2} display="flex" alignItems="center">
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            color: '#000',
            maxWidth: '180px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {name}
        </Typography>
        <ExpandMoreIcon sx={{ ml: 1, color: '#000' }} />
      </Box>
    </Box>
  );
};

export default AccountAvatar;
