import React from 'react';
import { Box, Grid } from '@mui/material';
import Button from 'components/Button';

const MarkAsReadContent = ({ handleUpdateMarkNotification, notifyCount }) => {
  return (
    <Box className="footer_read_head">
      <Button
        type="button"
        onClick={handleUpdateMarkNotification}
        sx={{ color: '#fff' }}
        fullWidth
        disabled={notifyCount === 0}
      >
        Mark all as read
      </Button>
    </Box>
  );
};

export default MarkAsReadContent;
