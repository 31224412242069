export const notifyFilterBtnData = [
  {
    id: 1,
    name: 'All',
    count: '4',
    value: 'all'
  },
  {
    id: 2,
    name: 'Proposal',
    count: '0',
    value: 'Proposal'
  },
  {
    id: 3,
    name: 'Invoice',
    count: '0',
    value: 'Invoice'
  },
  {
    id: 4,
    name: 'Appointments',
    count: '0',
    value: 'Appointment'
  },
];
