import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import DrawIcon from '@mui/icons-material/Draw';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import { Typography, Box } from '@mui/material';
import moment from 'moment';

export const industryOptions = [
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Plumbing', value: 'Plumbing' },
  { label: 'Mechanical', value: 'Mechanical' },
];

export const employeeOptions = [
  { label: '1', value: '1' },
  { label: '2 - 5', value: '2-5' },
  { label: '6 - 15', value: '6-15' },
  { label: '16 - 50', value: '16-50' },
  { label: '51 - 100', value: '51-100' },
  { label: '101+', value: '101+' },
];

export const columns = [
  { field: 'id', headerName: 'ID', width: 300 },
  { field: 'name', headerName: 'Name', width: 300 },
  { field: 'email', headerName: 'Email', width: 300 },
  { field: 'phone', headerName: 'Phone', width: 300 },
  { field: 'role', headerName: 'Role', width: 300 },
];

export const rows = [
  {
    id: 1,
    firstName: 'Snow',
    lastName: 'Man',
    email: 'snow@jon.com',
    phone: '987654321',
    role: 'Sales Person',
  },
];

export const calendarOptions = [
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];

export const productTypeOptions = [
  { label: 'Service', value: 'service' },
  { label: 'Product', value: 'product' },
];

export const addPriceData = [
  { value: 'Price', status: 'NOT DEFINED', name: 'price' },
  { value: 'Min. Price', status: 'NOT DEFINED', name: 'minPrice' },
  { value: 'Cost', status: 'NOT DEFINED', name: 'cost' },
];

export const addCoversionRateData = [
  { value: 'from', name: 'from' },
  { value: 'to', name: 'to' },
  { value: 'rate', name: 'rate' },
];

export const listData = [{ name: 'Second Note' }, { name: 'First Note' }];

export const categoryList = [
  { label: 'Painting', value: 'painting' },
  { label: 'Roof Repairing', value: 'Roof Repairing' },
  { label: 'Exterior Painting', value: 'Exterior Painting' },
  { label: 'Wall Painting', value: 'Wall Painting' },
];

export const currencyOptions = [
  { label: 'USD', value: 'USD' },
  { label: 'INR', value: 'INR' },
];

export const timeOptions = [
  { label: 'Central Standard Time', value: 'Central Standard Time' },
  { label: 'Eastern Standard Time', value: 'Eastern Standard Time' },
  { label: 'Mountain Standard Time', value: 'Mountain Standard Time' },
  { label: 'Pacific Standard Time', value: 'Pacific Standard Time' },
  { label: 'Alaskan Standard Time', value: 'Alaskan Standard Time' },
  { label: 'Atlantic Standard Time', value: 'Atlantic Standard Time' },
];

export const colorOptions = [
  { label: 'Purple', value: '#8B5FBF' },
  { label: 'Red', value: '#FF0000' },
  { label: 'Salmon', value: '#FF8C69' },
  { label: 'Orange', value: '#FFA500' },
  { label: 'Yellow', value: '#FFFF00' },
  { label: 'Mint', value: '#98FB98' },
  { label: 'Green', value: '#008000' },
  { label: 'Dark Blue', value: '#00008B' },
  { label: 'Light Blue', value: '#ADD8E6' },
  { label: 'Pink', value: '#FFC0CB' },
  { label: 'Gray', value: '#808080' },
];

export const days = [
  { label: 'Monday', value: false },
  { label: 'Tuesday', value: false },
  { label: 'Wednesday', value: false },
  { label: 'Thursday', value: false },
  { label: 'Friday', value: false },
  { label: 'Saturday', value: false },
  { label: 'Sunday', value: false },
];

export const calendarColumns = [
  { field: 'id', headerName: 'ID', width: 300 },
  { field: 'name', headerName: 'Name', width: 300 },
  { field: 'color', headerName: 'Color', width: 300 },
];

export const calendarRows = [
  {
    id: 1,
    name: 'Punch-Out changed',
    color: 'Orange',
    colorCode: '#FFA500',
  },
];

export const durations = {
  top: [
    { label: '15 Min', value: 15 },
    { label: '30 Min', value: 30 },
    { label: '45 Min', value: 45 },
  ],
  bottom: [
    { label: '1 Hour', value: 60 },
    { label: '1.5 Hours', value: 90 },
    { label: '2 Hours', value: 120 },
  ],
};

export const notifications = [
  { label: 'New Appointment', value: false },
  { label: 'Proposal Accepted', value: false },
  { label: 'Proposal Rejected', value: false },
  { label: 'Proposal Viewed', value: true },
  { label: 'Invoice Viewed', value: false },
  { label: 'Invoice Paid', value: false },
];

export const proposalItems = [
  { id: 0, name: 'Title', value: true },
  { id: 1, name: 'Introduction', value: true },
  { id: 2, name: 'Quote Details', value: true },
  { id: 3, name: 'Terms and Conditions', value: true },
  { id: 4, name: 'Warranty', value: true },
];

export const proposals = [
  { id: 1, name: 'Quote Details', color: 'red' },
  { id: 2, name: 'Title', color: 'green' },
  { id: 3, name: 'Terms and Conditions', color: 'blue' },
  { id: 4, name: 'Warranty', color: 'black' },
];
export const introData = {
  text: `<p>Hi {{CUSTOMER_FIRST_NAME}},</p>
    <br /><br />

   <p>Thank you for the opportunity to quote on the painting of your home. Please find your estimate 
    below along with upgrade options for potential improvements to your project, if applicable.</p> 
    <br />

    <p>The following estimate is for:</p>
    <br />

    <ul>
    <li>Preparation of specified surfaces</li>
    <li>Supply of all products and materials</li>
    <li>1 or 2 Full coats to all selected surfaces (see quote details)</li>
    <li>Clean up upon completion</li>
    <li>Safety certification and coverage for all employees</li>
    <li>Full Licensing to work in your geographical region</li>
    <li>Preparation of specified surfaces</li>
    <li>Preparation of specified surfaces</li>
    <li>Final inspection of all work completed by you the owner</li>
    <li>2-Year Workmanship Warranty on complete projects</li>
    </ul>
    <br />

    <p>We don’t want you to be personally liable should a worker happen to get injured therefore, 
    we maintain current liability insurance for all employees and crews. We carry $2,000,000 liability insurance.</p>
   
    <br />  

    <p>Once the job is complete, we will inspect your project 
    to make sure we did everything correct and up to our strict standards and site is spotless.</p>

    <br />

    <p>If you have any questions, please give me a call. We always want to provide the best service to our clients. If we are outside your budget, 
    please let us know and we will do our best to work within that!</p>

    <br />

    <p>As per your request, I have also included some optional upgrades for you should you wish to have a premium paint or finish completed on your home.
     These are available on the Authorisation page for you to review.</p> 
    <br /><br />

    <p>Kind regards,</p>
    <br />

    <p><b>{{SALESPERSON_NAME}}</b></p>
    <p>{{SALESPERSON_EMAIL}}</p>
    <p>{{SALESPERSON_PHONE}}</p>

    `,
};

export const paintingQuote = [
  {
    label: 'Exterior Painting Quote - House',
    value: 'Exterior Painting Quote - House',
  },
  { label: 'Interior Painting Quote', value: 'Interior Painting Quote' },
];

export const marginValues = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '100',
  },
];

export const termsConditions = [
  `
  You may cancel this contract from the day you enter 
  into the contract until 10 days after you receive a 
  copy of the contract. You do not need a reason to cancel. 
  If you do not receive the goods or services within 
  30 days of the date stated in the contract, you may cancel 
  this contract within one year of the contract date. 
  You lose that right if you accept delivery after the 30 days. 
  There are other grounds for extended cancellation. For 
  more information, you may contact your provincial/ territorial consumer 
  affairs office. If you cancel this contract, the seller has 15 days 
  to refund your money and any trade-in, or the cash value of the trade-in. 
  You must then return the goods. To cancel, you must give notice of 
  cancellation at the address in this contract. You must give notice of 
  cancellation by a method that will allow you to prove that you gave notice,
   including registered mail, fax or by personal delivery.
  `,

  `I understand that I must remove any personal 
    items from the areas that may be part of this project, 
    and that damage to personal items not moved mau occur. 
    COMPANY NAME is not liable for such damages.`,

  `I understand that hard edges of previous paint may 
    remain on a re-paint project. The previous condition 
    of the surfaces selected largely determine the smoothness 
    of the final finish. COMPANY NAME is not liable for 
    repairing/smoothing out previous damages or hard edges 
    from previously peeling surfaces.`,

  `I understand that any warranty for material 
    used during the project is provided by the material manufacturer. 
    Unless agreed upon otherwise, COMPANY NAME provides a 2-year 
    Workmanship Warranty on portions of the project on which 
    COMPANY NAME applied 2 coats of supplied products. In the case 
    of a homeowner supplying their own products for a painting project,
     this warranty is voided.`,

  `I understand that surfaces where water is regularly 
    running/sitting will not be warranted against failure. 
    These surfaces include (but are not limited to) painted decks, 
    fences, flower beds/pots, surfaces in contact with the ground,
    surfaces near leaking gutters or downspouts. Any floor 
    surfaces are not covered by this warranty.`,

  `I certify that I am the registered owner of the 
    above project property, or have the legal permission to 
    authorize COMPANY NAME to perform the work as stated and 
    agree to pay the total project price.`,

  `I understand that any insurance claims are subject to the 
    specific terms and conditions outlined by my insurance company, 
    and may be subject to insurance company approval.`,

  `I understand that payment in full is due upon completion 
    of work as stated in contract. All invoices not paid in full after 
    15 days will be subject to a 2% per month interest charge.`,

  `I understand that approval of my estimate is subject 
    to customer credit approval by COMPANY NAME. I agree that 
    COMPANY NAME may access my credit bureau report(s), trade references, 
    and other credit information prior to granting credit approval.`,
];

export const warrantyData = {
  headText: `
  This document warrants that should a defect in workmanship, 
  related to the work completed by COMPANY NAME, occur within 2 years of 
  the project, COMPANY NAME will complete repairs within the original project’s 
  scope of work at no charge to the customer. This warranty does not cover normal 
  wear and tear, hail damage, wind damage, sun damage, intentional or accidental 
  damage by any person, or acts of God that may or may not merit an insurance claim. 
  This warranty only applies to portions of the project in which COMPANY NAME applied 
  2 coats of provided products. Defects in the building materials used to complete 
  work do not fall under the scope of this workmanship warranty; any building products 
  installed will instead be covered by the product’s original manufacturer warranty.
  `,
  arrayList: [
    { header: 'Customer name', value: 'Test User' },
    { header: 'Project #', value: 'My test Project-1' },
    {
      header: 'Address',
      value: 'address line 1, address line 2, city, state, 1234',
    },
    {
      header: 'Date project completed',
      value:
        'Thank you again for choosing COMPANY NAME to complete work on your property. We trust you had a great customer experience!',
    },
  ],
};

export const quoteSection = [
  {
    title: '',
    price: '',
    sectionItemArray: [
      {
        paint: '',
        qty: '',
        price: '',
        lineName: '',
      },
    ],
  },
];

export const unitArray = [
  { label: 'SQ', value: 'SQ' },
  { label: 'SQFT', value: 'SQFT' },
  { label: 'LF', value: 'LF' },
  { label: 'EA', value: 'EA' },
  { label: 'PC', value: 'PC' },
  { label: 'GAL', value: 'GAL' },
  { label: 'HR', value: 'HR' },
  { label: 'BDL', value: 'BDL' },
];

export const addTabs = [{ id: 0, name: 'Painting Quote' }];

export const contactTypes = [
  { label: 'Homeowner', value: 'Homeowner' },
  { label: 'Builder', value: 'Builder' },
  { label: 'Company', value: 'Company' },
  { label: 'Other', value: 'Other' },
];

export const leadSourceArray = [
  { label: 'Google', value: 'Google' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Word of Mouth', value: 'Word of Mouth' },
  { label: 'Angi Leads', value: 'Angi Leads' },
  { label: 'Yard Sign', value: 'Yard Sign' },
  { label: 'Repeat Customer', value: 'Repeat Customer' },
  { label: 'Website', value: 'Website' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Phone Call', value: 'Phone Call' },
  { label: 'Mail', value: 'Mail' },
  { label: 'Other', value: 'Other' },
];

export const recentTabs = [
  {
    label: 'Contact 1',
    value: 'Contact 1',
  },
  {
    label: 'Contact 2',
    value: 'Contact 2',
  },
  {
    label: 'Contact 3',
    value: 'Contact 3',
  },
  {
    label: 'Contact 4',
    value: 'Contact 4',
  },
  {
    label: 'Contact 5',
    value: 'Contact 5',
  },
];

export const quoteDetailsModalData = [
  {
    id: 0,
    label: 'Show Quantity',
    value: 'Show Quantity',
    name: 'showQuantity',
    checked: false,
  },

  {
    id: 1,
    label: 'Show Unit Price',
    value: 'Show Unit Price',
    name: 'showUnitPrice',
    checked: false,
  },

  {
    id: 2,
    label: 'Show Line Total',
    value: 'Show Line Total',
    name: 'showLineTotal',
    checked: false,
  },

  {
    id: 3,
    label: 'Show Section Total',
    value: 'Show Section Total',
    name: 'showSectionTotal',
    checked: false,
  },

  {
    id: 4,
    label: 'Customer Can Select Only One Quote',
    value: 'Customer Can Select Only One Quote',
    name: 'selectOnlyOneQuote',
    checked: false,
  },
];

export const templateArray = [
  {
    id: 1,
    page_id: 1,
    label: 'Proposals',
  },
  {
    id: 2,
    page_id: 2,
    label: 'Title',
  },
  {
    id: 3,
    page_id: 3,
    label: 'Introduction',
  },
  {
    id: 4,
    page_id: 4,
    label: 'Term and Conditions',
  },
  {
    id: 5,
    page_id: 5,
    label: 'Warranty',
  },
];

export const sortClientArray = [
  {
    label: 'Recently Active',
    value: 'Recently Active',
  },
  {
    label: 'First Name',
    value: 'First Name',
  },
  {
    label: 'Last Name',
    value: 'Last Name',
  },
];

export const filterClientArray = [
  {
    label: 'All Results',
    value: 'All Results',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Archived',
    value: 'Archived',
  },
];

export const clientPropertyArr = [
  {
    id: 1,
    address: '2100 Northeast 140th Street',
    city: 'Seattle',
    state: 'Washington',
    pincode: 98125,
  },

  {
    id: 2,
    address: '5155 South Wildwood Lane',
    city: 'Seattle',
    state: 'Washington',
    pincode: 98118,
  },
  {
    id: 3,
    address: '2100 Northeast 140th Street',
    city: 'Seattle',
    state: 'Washington',
    pincode: 98125,
  },
  {
    id: 4,
    address: '5155 South Wildwood Lane',
    city: 'Seattle',
    state: 'Washington',
    pincode: 98118,
  },
  {
    id: 5,
    address: '2100 Northeast 140th Street',
    city: 'Seattle',
    state: 'Washington',
    pincode: 98125,
  },
  {
    id: 6,
    address: '5155 South Wildwood Lane',
    city: 'Seattle',
    state: 'Washington',
    pincode: 98118,
  },
  {
    id: 7,
    address: '2100 Northeast 140th Street',
    city: 'Seattle',
    state: 'Washington',
    pincode: 98125,
  },
  {
    id: 8,
    address: '5155 South Wildwood Lane',
    city: 'Seattle',
    state: 'Washington',
    pincode: 98118,
  },
  {
    id: 9,
    address: '2100 Northeast 140th Street',
    city: 'Seattle',
    state: 'Washington',
    pincode: 98125,
  },
  {
    id: 10,
    address: '5155 South Wildwood Lane',
    city: 'Seattle',
    state: 'Washington',
    pincode: 98118,
  },
];

export const editProposal = [
  {
    id: 1,
    name: 'Blank',
  },
];

export const stageWithStatus = [
  {
    stage: 'Leads',
    status: 'New Lead',
    type: 'warning',
  },
  {
    stage: 'Estimate Requested',
    status: 'Requested',
    type: 'pink',
  },
  {
    stage: 'Estimate Scheduled',
    status: 'Scheduled',
    type: 'info',
  },
  {
    stage: 'In Draft',
    status: 'Draft',
    type: 'warning',
  },
  {
    stage: 'Proposal(s) Sent',
    status: 'Sent',
    type: 'success',
  },
  {
    stage: 'Proposal(s) Rejected',
    status: 'Rejected',
    type: 'danger',
  },
];

export const dealArray = [
  'Leads',
  'Estimate Requested',
  'Estimate Scheduled',
  'In Draft',
  'Proposal(s) Sent',
  'Proposal(s) Rejected',
];

export const clientCountriesData = [
  {
    id: 1,
    label: 'India',
    value: 'India',
  },
  {
    id: 2,
    label: 'Canada',
    value: 'Canada',
  },
  {
    id: 3,
    label: 'Afghanistan',
    value: 'Afghanistan',
  },
  {
    id: 4,
    label: 'Comoros',
    value: 'Comoros',
  },
  {
    id: 5,
    label: 'France',
    value: 'France',
  },
  {
    id: 6,
    label: 'United States',
    value: 'United States',
  },
  {
    id: 7,
    label: 'Hong Kong',
    value: 'Hong Kong',
  },
  {
    id: 8,
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
  },
  {
    id: 9,
    label: 'Maldives',
    value: 'Maldives',
  },
  {
    id: 10,
    label: 'New Zealand',
    value: 'New Zealand',
  },
];

export const clientTagsData = [
  {
    id: 1,
    tagName: 'paint',
  },
  {
    id: 2,
    tagName: 'design',
  },
  {
    id: 3,
    tagName: 'construct',
  },
  {
    id: 4,
    tagName: 'walldesign',
  },
  {
    id: 5,
    tagName: 'quote',
  },
];

export const clientBillingHistoryData = [
  {
    id: 1,
    label: 'Collect Payment',
    value: 'Collect Payment',
  },
  {
    id: 2,
    label: 'Record Deposit',
    value: 'Record Deposit',
  },
  {
    id: 3,
    label: 'Invoice',
    value: 'Invoice',
  },
  {
    id: 4,
    label: 'Statement',
    value: 'Statement',
  },
  {
    id: 5,
    label: 'Set Initial Balance',
    value: 'Set Initial Balance',
  },
];

export const billingRecordData = [
  {
    id: 1,
    label: 'Payment for invoice #88',
    value: '-$9,265.06',
  },

  {
    id: 2,
    label: 'Invoice #88',
    value: '$18,530.13',
  },

  {
    id: 3,
    label: 'Deposit for quote #159, invoice #88',
    value: '-$9,265.07',
  },
];

export const clientOverViewData = [
  {
    id: 1,
    label: 'Request',
    value: 'Request',
    icon: <AssignmentReturnedIcon />,
  },
  {
    id: 2,
    label: 'Proposal',
    value: 'Proposal',
    icon: <DrawIcon />,
  },
  {
    id: 3,
    label: 'Job',
    value: 'Job',
    icon: <WorkIcon />,
  },
  {
    id: 4,
    label: 'Invoice',
    value: 'Invoice',
    icon: <StrikethroughSIcon />,
  },
];

export const clientInvoiceData = [
  {
    id: 1,
    number: 'Invoice  #88',
    date: moment().format('MMMM Do, YYYY'),
    subject: 'For Services Rendered',
    total: '$18,530.13',
    balance: '$0.00',
  },

  {
    id: 2,
    number: 'Invoice  #88',
    date: moment().format('MMMM Do, YYYY'),
    subject: 'For Services Rendered',
    total: '$18,530.13',
    balance: '$0.00',
  },

  {
    id: 3,
    number: 'Invoice  #88',
    date: moment().format('MMMM Do, YYYY'),
    subject: 'For Services Rendered',
    total: '$18,530.13',
    balance: '$0.00',
  },

  {
    id: 4,
    number: 'Invoice  #88',
    date: moment().format('MMMM Do, YYYY'),
    subject: 'For Services Rendered',
    total: '$18,530.13',
    balance: '$0.00',
  },

  {
    id: 5,
    number: 'Invoice  #88',
    date: moment().format('MMMM Do, YYYY'),
    subject: 'For Services Rendered',
    total: '$18,530.13',
    balance: '$0.00',
  },
];

export const clientJobSectionData = [
  {
    id: 1,
    jobTitle: 'Job #13 - ',
    jobStatus: 'Has a late visit',
    dateHead: 'Scheduled for',
    date: 'Sep 23, 2022',
    address1: '5155 South Wildwood Lane',
    address2: 'Seattle, Washington  98118',
    totalPrice: '$17,700.00',
  },
  {
    id: 2,
    jobTitle: 'Job #14 - ',
    jobStatus: 'Archived',
    dateHead: 'Scheduled for',
    date: 'Sep 23, 2022',
    address1: '5155 South Wildwood Lane',
    address2: 'Seattle, Washington  98118',
    totalPrice: '$23,826.90',
  },
];

export const clientScheduleData = [
  {
    id: 1,
    label: 'Request',
    value: 'Request',
    icon: <AssignmentReturnedIcon />,
  },

  {
    id: 2,
    label: 'Task',
    value: 'Task',
    icon: <AssignmentIcon />,
  },
  {
    id: 3,
    label: 'Calendar Event',
    value: 'Calendar Event',
    icon: <CalendarMonthIcon />,
  },
];

export const clientJobStatusData = [
  {
    id: 1,
    status: 'Overdue',
    checkValue: false,
    title: 'Visit for job #13',
    clientName: 're: Adam',
    date: 'Sep 23, 2022 4:00PM',
    assigneeText: 'Assigned to Admin',
  },
  {
    id: 2,
    status: 'Completed Today',
    checkValue: true,
    title: 'Visit for job #14',
    clientName: 're: Adam',
    date: 'Sep 23, 2022 4:00PM',
    assigneeText: 'Assigned to Admin',
  },
];

export const ClientPricingPropoertyData = [
  {
    id: 1,
    lineItem: 'Ceiling',
    quoted: '$0.00*',
    job: '$0.00*',
  },
  {
    id: 2,
    lineItem: 'Primer',
    quoted: '$0.00*',
    job: '$0.00*',
  },
  {
    id: 3,
    lineItem: 'Walls',
    quoted: '$0.00*',
    job: '$0.00*',
  },
  {
    id: 4,
    lineItem: 'Warranty',
    quoted: '$0.00*',
    job: '$0.00*',
  },
  {
    id: 5,
    lineItem: 'Deposit & cancellation',
    quoted: '$0.00*',
    job: '$0.00*',
  },
  {
    id: 6,
    lineItem: 'Labor, paint, and materials',
    quoted: '$17,700.00*',
    job: '$17,700.00*',
  },
  {
    id: 7,
    lineItem: 'Sanding',
    quoted: '$0.00*',
    job: '$0.00*',
  },
  {
    id: 8,
    lineItem: 'Interior masking',
    quoted: '$0.00*',
    job: '$0.00*',
  },
  {
    id: 9,
    lineItem: 'Interior Cleaning',
    quoted: '$0.00*',
    job: '$0.00*',
  },
];

export const clientActions = [
  {
    id: 1,
    value: 'Import',
    label: 'Import',
    icon: <NorthEastIcon />,
    disabled: false,
  },
  {
    id: 2,
    value: 'Export',
    label: 'Export',
    icon: <SouthWestIcon />,
    disabled: true,
  },
];

export const ansTypeOptions = [
  {
    id: 1,
    label: 'Short Answer',
    value: 'short_answer',
  },
  {
    id: 2,
    label: 'Checkboxes',
    value: 'checkbox',
  },
  {
    id: 3,
    label: 'Dropdown',
    value: 'dropdown',
  },
];

export const editScheduleActions = [
  {
    id: 1,
    label: 'View Job',
    value: 'view job',
  },
  {
    id: 2,
    label: 'Download Work Order',
    value: 'download work order',
  },
];

export const apptTypeData = [
  {
    label: <Typography width={302}>Single Day Appointment</Typography>,
    value: 1,
    type: 'single-day',
  },
  {
    label: <Typography width={302}>Multiple Day’s Appointment</Typography>,
    value: 2,
    type: 'multiple-day',
  },
];

export const recurringTypeData = [
  {
    id: 1,
    label: `Weekly on ${moment(new Date()).format('dddd')}`,
    value: 'weekly',
  },
  {
    id: 2,
    label: `Every 2 weeks on ${moment(new Date()).format('dddd')}`,
    value: 'two-week',
  },
  {
    id: 3,
    label: `Monthly on the ${new Date().getDate()} day of the month`,
    value: 'monthly',
  },
  {
    id: 4,
    label: 'Custom Schedule',
    value: 'custom',
  },
];

export const scheduleJobTypeData = [
  {
    label: (
      <Box sx={{ width: '100%', maxWidth: '300px' }}>
        <Typography width={300} mb={1}>
          One-Off Job
        </Typography>
        <Typography width={300} fontSize={14}>
          A one time job with one or more visits
        </Typography>
      </Box>
    ),
    value: 1,
    type: 'one-off-job',
  },
  {
    label: (
      <Box sx={{ width: '100%', maxWidth: '300px' }}>
        <Typography width={300} mb={1}>
          Recurring Job
        </Typography>
        <Typography width={300} fontSize={14}>
          A contract job with repeating visits
        </Typography>
      </Box>
    ),
    value: 2,
    type: 'recurring-job',
  },
];

export const selectedFrequencyData = [
  {
    id: 1,
    label: `Daily`,
    value: 'daily',
    freqData: 'days(s)',
  },
  {
    id: 2,
    label: `Weekly`,
    value: 'weekly',
    freqData: 'week(s)',
  },
  {
    id: 3,
    label: `Monthly`,
    value: 'monthly',
    freqData: 'month(s)',
  },
  {
    id: 4,
    label: 'Yearly',
    value: 'yearly',
    freqData: 'year(s)',
  },
];

export const repeatWeeklyData = [
  {
    id: 1,
    label: `Sunday`,
    value: 'Sun',
  },
  {
    id: 2,
    label: `Monday`,
    value: 'Mon',
  },
  {
    id: 3,
    label: `Tuesday`,
    value: 'Tue',
  },
  {
    id: 4,
    label: 'Wednesday',
    value: 'Wed',
  },
  {
    id: 5,
    label: 'Thursday',
    value: 'Thu',
  },
  {
    id: 6,
    label: 'Friday',
    value: 'Fri',
  },
  {
    id: 7,
    label: 'Saturday',
    value: 'Sat',
  },
];

export const calenderData = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export const quoteTypeFreqData = [
  {
    id: 1,
    label: 'Monthly',
    value: 'monthly',
  },
  {
    id: 2,
    label: 'Weekly',
    value: 'weekly',
  },
  {
    id: 3,
    label: 'Quarterly',
    value: 'quarterly',
  },
  {
    id: 4,
    label: 'Bi-Weekly',
    value: 'bi-weekly',
  },
  {
    id: 5,
    label: 'Annually',
    value: 'annually',
  },
  {
    id: 6,
    label: 'Every Other Month',
    value: 'every other month',
  },
  {
    id: 7,
    label: 'On Request',
    value: 'on request',
  },
];

export const bookingRequestType = [
  {
    id: 1,
    value: 'email',
    label: 'Email',
  },
  {
    id: 2,
    value: 'sms',
    label: 'SMS',
  },
];
