export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const CREATE_USER_NOTE_REQUEST = 'CREATE_USER_NOTE_REQUEST';
export const CREATE_USER_NOTE_SUCCESS = 'CREATE_USER_NOTE_SUCCESS';
export const CREATE_USER_NOTE_FAIL = 'CREATE_USER_NOTE_FAIL';

export const GET_USER_NOTES_REQUEST = 'GET_USER_NOTES_REQUEST';
export const GET_USER_NOTES_SUCCESS = 'GET_USER_NOTES_SUCCESS';
export const GET_USER_NOTES_FAIL = 'GET_USER_NOTES_FAIL';

export const DELETE_USER_NOTE_REQUEST = 'DELETE_USER_NOTE_REQUEST';
export const DELETE_USER_NOTE_SUCCESS = 'DELETE_USER_NOTE_SUCCESS';
export const DELETE_USER_NOTE_FAIL = 'DELETE_USER_NOTE_FAIL';

export const CREATE_ATTACHMENT_REQUEST = 'CREATE_ATTACHMENT_REQUEST';
export const CREATE_ATTACHMENT_SUCCESS = 'CREATE_ATTACHMENT_SUCCESS';
export const CREATE_ATTACHMENT_FAIL = 'CREATE_ATTACHMENT_FAIL';

export const GET_ATTACHMENTS_REQUEST = 'GET_ATTACHMENTS_REQUEST';
export const GET_ATTACHMENTS_SUCCESS = 'GET_ATTACHMENTS_SUCCESS';
export const GET_ATTACHMENTS_FAIL = 'GET_ATTACHMENTS_FAIL';

export const DELETE_ATTACHMENT_REQUEST = 'DELETE_ATTACHMENT_REQUEST';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAIL = 'DELETE_ATTACHMENT_FAIL';

export const ATTACHMENT_PROGRESS = 'ATTACHMENT_PROGRESS';
export const RESET_ATTACHMENT_PROGRESS = 'RESET_ATTACHMENT_PROGRESS';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';

export const RESET_USER_REFETCH = 'RESET_USER_REFETCH';

export const UPDATE_USERS_PAGE = 'UPDATE_USERS_PAGE';
