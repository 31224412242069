import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Person2Icon from '@mui/icons-material/Person2';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
// import MenuIcon from '@mui/icons-material/Menu';

import AccountAvatar from 'components/PprivateLayout/AccountAvatar';
import NotificationBell from 'components/PprivateLayout/NotificationBell';
import NotifyBarContent from 'components/PprivateLayout/NotifyBarContent';
import NotifyListData from 'components/PprivateLayout/NotifyListData';
// import ToggleIcon from 'assets/png/toggle-icon.png';
// import Button from 'components/Button';
import MarkAsReadContent from 'components/PprivateLayout/MarkAsReadContent';
import * as notifyActions from 'redux/notifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import Logo from 'assets/svg/logo.svg';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const drawerWidth = 310;
const closeDrawer = 56;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  borderBottom: '1px solid #fff',
  margin: '30px 0px 0px 14px',
  borderRadius: '10px',
  background: '#FFF',
  right: '25px',
  ...(open && {
    width: open
      ? `calc(100% - ${drawerWidth}px)`
      : `calc(100% - ${closeDrawer}px)`,
    marginLeft: open ? `${drawerWidth}px` : `${closeDrawer}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const settings = [
  {
    id: 1,
    title: 'My Profile',
    icon: <Person2Icon />,
  },
  {
    id: 2,
    title: 'Change Password',
    icon: <LockIcon />,
  },
  {
    id: 3,
    title: 'Logout',
    icon: <LogoutIcon />,
  },
];

const MyAppBar = ({
  name,
  initials,
  src,
  open,
  handleDrawer,
  selected,
  handleMenu,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [settingsModal, setSettingsModal] = React.useState(false);
  const [tabValue, setTabValue] = React.useState('all');
  const [notifyId, setNotifyId] = React.useState('');

  const { loadingAll, notifyAll, fetchUpdating } = useSelector(
    (state) => state.notification
  );

  const { page } = notifyAll;
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [notitfy, setNotify] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNotifyMenu = (e) => {
    setNotify(e.currentTarget);
  };

  const handleViewNotifyData = () => {
    console.log('menu data');
  };

  const handleCloseNotifyBar = (e, menu) => {
    setNotify(null);
    // setTimeout(() => {
    //   handleViewNotifyData();
    // }, 100);
  };

  const handleCloseUserMenu = (event, menu) => {
    setAnchorElUser(null);
    setTimeout(() => {
      handleMenu(menu);
    }, 100);
  };

  const handleUpdateMarkNotification = () => {
    let data = {
      status: true,
      notificationId: notifyId,
    };
    dispatch(notifyActions?.updatedMarkAsReadNotifications(data, 'all'));
  };

  const handleViewPage = (event, item) => {
    event.stopPropagation();
    if (item?.sourceModel === 'Appointment') {
      navigate('/appointments');
    }
    if (item?.sourceModel === 'Proposal') {
      navigate(`/proposals/accept-proposal/${item?.sourceId}`);
    }
    setNotify(null);
  };

  const handleClickView = (item) => {
    setNotifyId(item?.id);
    let data = {
      status: true,
      notificationId: item?.id,
    };
    dispatch(notifyActions?.updatedMarkAsReadNotifications(data, 'all'));
  };

  React.useEffect(() => {
    if (tabValue) {
      dispatch(notifyActions?.getAllUsersNotification(tabValue, page || 1));
    }
  }, [tabValue, dispatch, page, fetchUpdating]);

  return (
    <AppBar
      className={open ? 'open_header_bar' : 'homeOwner_close_header_bar'}
      position="fixed"
      open={open}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="homeOwner open drawer"
          onClick={handleDrawer}
          edge="start"
          sx={{
            position: 'absolute',
            left: open ? 16 : 72,
          }}
        >
          <KeyboardArrowLeftIcon className={open ? '' : 'toggle_rotate'} />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {/* <Box>My Projects</Box> */}
          <Box></Box>
          {/* <Box>
            <img src={Logo} alt="" className="portal-logo" />
          </Box>  */}

          <Box>
            <Box sx={{ display: 'flex' }}>
              {/* <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="notify_bell_btn"
                onClick={handleOpenNotifyMenu}
                sx={{
                  mr: 2,
                  p: 0,
                }}
              >
                <NotificationBell
                  className="bell_btn_icon"
                  count={
                    notifyAll?.unreadCount > 0 ? notifyAll?.unreadCount : 0
                  }
                />
              </IconButton> */}

              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0, borderRadius: 0 }}
              >
                <AccountAvatar name={name} src={src} initials={initials} />
              </IconButton>
            </Box>

            <Menu
              className="noti_menu_show"
              PaperProps={{
                sx: {
                  width: '100%',
                  margin: '44px 0px 0px 0px',
                  maxWidth: '545px',
                  boxShadow: '0 8px 20px rgba(127,82,251,0.25)',
                  borderRadius: '10px',
                  overflow: 'hidden',
                },
              }}
              // id="menu-appbar"
              anchorEl={notitfy}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(notitfy)}
              onClose={handleCloseNotifyBar}
              MenuListProps={{
                disablePadding: true,
              }}
            >
              <MenuItem
                className="notify_bar_menu"
                // onClick={(e) => handleCloseNotifyBar(e)}
              >
                <NotifyBarContent
                  setNotify={setNotify}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  loading={loadingAll}
                  notifyCount={notifyAll?.count}
                  notifyUnreadCount={notifyAll?.unreadCount}
                  handleUpdateMarkNotification={handleUpdateMarkNotification}
                />
              </MenuItem>

              <NotifyListData
                notifyAll={notifyAll}
                handleClickView={handleClickView}
                handleViewPage={handleViewPage}
              />

              <MarkAsReadContent
                handleUpdateMarkNotification={handleUpdateMarkNotification}
                notifyCount={notifyAll?.unreadCount}
              />
            </Menu>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.id}
                  onClick={(e) => handleCloseUserMenu(e, setting)}
                >
                  <Box alignItems="center" display="flex">
                    <Box>{setting.icon}</Box>
                    <Box sx={{ marginLeft: 1 }}>{setting.title}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;
