import { lazy } from 'react';
// import projectFolder from 'assets/svg/HomeOwner/projectFolder.svg';
import projectFolder from 'assets/homeowner-icons/my-projects.svg';
import proposalsIcon from 'assets/homeowner-icons/proposals-icons.svg';
import invoiceIcon from 'assets/homeowner-icons/invoice-list.svg';
import apptIcon from 'assets/homeowner-icons/appt-icon.svg';
import usersIcon from 'assets/homeowner-icons/user icon.svg';

const MyProjects = lazy(() => import('pages/HomeOwner/MyProjects'));
const ViewProject = lazy(() =>
  import('pages/HomeOwner/MyProjects/ViewProject')
);
const MyProposals = lazy(() => import('pages/HomeOwner/MyProposals'));
const CompareProposal = lazy(() =>
  import('pages/HomeOwner/MyProjects/CompareProposal')
);
const CompareChat = lazy(() =>
  import('pages/HomeOwner/MyProjects/CompareChat')
);
const ClientProposals = lazy(() =>
  import('pages/AcceptProposal/ClientProposals')
);
const AcceptProposals = lazy(() => import('pages/AcceptProposal/MyProposal'));
const ClientInvoice = lazy(() => import('pages/AcceptProposal/client-invoice'));
const InvoicePayment = lazy(() =>
  import('pages/AcceptProposal/client-invoice/paymentInvoice')
);
const AppointmentRequest = lazy(() =>
  import('pages/Appointments/AppointmentRequest')
);
const ClientProfile = lazy(() => import('pages/Profile'));

const privateRoutes = [
  {
    id: 1,
    title: 'My Projects',
    icon: projectFolder,
    path: '/',
    component: <MyProjects />,
    hidden: true,
  },
  {
    id: 2,
    title: 'My Projects',
    icon: projectFolder,
    path: '/projects',
    component: <MyProjects />,
    hidden: false,
  },
  {
    id: 3,
    title: 'My Projects',
    icon: projectFolder,
    path: '/projects/viewProject/:projectId',
    component: <ViewProject />,
    hidden: true,
  },
  {
    id: 4,
    title: 'My Projects',
    icon: projectFolder,
    path: '/projects/:proposalId/:isSubscribed/:statusType',
    component: <MyProposals />,
    hidden: true,
  },
  {
    id: 5,
    title: 'Compare Proposals',
    icon: projectFolder,
    path: '/projects/compare/:projectId',
    component: <CompareProposal />,
    hidden: true,
  },
  {
    id: 6,
    title: 'Chat with Proposals',
    icon: projectFolder,
    path: '/projects/:projectId/compareChat/:inviteIds',
    component: <CompareChat />,
    hidden: true,
  },
  {
    id: 7,
    title: 'My Proposals',
    icon: proposalsIcon,
    path: '/proposals',
    component: <ClientProposals />,
    hidden: false,
  },
  {
    id: 8,
    title: 'My Proposals',
    icon: proposalsIcon,
    path: '/proposals/accept-proposal/:proposalId',
    component: <AcceptProposals />,
    hidden: true,
  },
  {
    id: 9,
    title: 'My Invoices',
    icon: invoiceIcon,
    path: '/invoices',
    component: <ClientInvoice />,
    hidden: false,
  },
  {
    id: 10,
    title: 'My Invoices',
    icon: proposalsIcon,
    path: '/invoices/paybill/invoice/:invoiceId',
    component: <InvoicePayment />,
    hidden: true,
  },
  {
    id: 11,
    title: 'Request Appointment',
    icon: apptIcon,
    path: '/appointment-request',
    component: <AppointmentRequest />,
    hidden: false,
  },
  {
    id: 12,
    title: 'Profile',
    icon: usersIcon,
    path: '/profile',
    component: <ClientProfile />,
    hidden: true,
  },
];

export default privateRoutes;
