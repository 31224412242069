// ---------------GET ALL STRIPE PAYMENT METHODS REQUEST----------------

export const GET_ALL_STRIPE_PAYMENT_METHODS_REQUEST =
  'GET_ALL_STRIPE_PAYMENT_METHODS_REQUEST';
export const GET_ALL_STRIPE_PAYMENT_METHODS_SUCCESS =
  'GET_ALL_STRIPE_PAYMENT_METHODS_SUCCESS';
export const GET_ALL_STRIPE_PAYMENT_METHODS_FAIL =
  'GET_ALL_STRIPE_PAYMENT_METHODS_FAIL';

// ---------------ADD STRIPE PAYMENT METHODS REQUEST----------------

export const ADD_STRIPE_PAYMENT_METHOD_REQUEST =
  'ADD_STRIPE_PAYMENT_METHOD_REQUEST';
export const ADD_STRIPE_PAYMENT_METHOD_SUCCESS =
  'ADD_STRIPE_PAYMENT_METHOD_SUCCESS';
export const ADD_STRIPE_PAYMENT_METHOD_FAIL = 'ADD_STRIPE_PAYMENT_METHOD_FAIL';

// ---------------UPDATE STRIPE PAYMENT DEFAULT CARD REQUEST----------------

export const UPDATE_STRIPE_PAYMENT_DEFAULT_CARD_REQUEST =
  'UPDATE_STRIPE_PAYMENT_DEFAULT_CARD_REQUEST';
export const UPDATE_STRIPE_PAYMENT_DEFAULT_CARD_SUCCESS =
  'UPDATE_STRIPE_PAYMENT_DEFAULT_CARD_SUCCESS';
export const UPDATE_STRIPE_PAYMENT_DEFAULT_CARD_FAIL =
  'UPDATE_STRIPE_PAYMENT_DEFAULT_CARD_FAIL';

// ---------------UPDATE STRIPE PAYMENT CARD REQUEST----------------

export const UPDATE_STRIPE_PAYMENT_CARD_REQUEST =
  'UPDATE_STRIPE_PAYMENT_CARD_REQUEST';
export const UPDATE_STRIPE_PAYMENT_CARD_SUCCESS =
  'UPDATE_STRIPE_PAYMENT_CARD_SUCCESS';
export const UPDATE_STRIPE_PAYMENT_CARD_FAIL =
  'UPDATE_STRIPE_PAYMENT_CARD_FAIL';

// ---------------DELETE STRIPE PAYMENT CARD REQUEST----------------

export const DELETE_STRIPE_PAYMENT_CARD_REQUEST =
  'DELETE_STRIPE_PAYMENT_CARD_REQUEST';
export const DELETE_STRIPE_PAYMENT_CARD_SUCCESS =
  'DELETE_STRIPE_PAYMENT_CARD_SUCCESS';
export const DELETE_STRIPE_PAYMENT_CARD_FAIL =
  'DELETE_STRIPE_PAYMENT_CARD_FAIL';

// ---------------RESET REFETCH STRIPE PAYMENT REQUEST----------------

export const RESET_STRIPE_PAYMENT_REQUEST = 'RESET_STRIPE_PAYMENT_REQUEST';
