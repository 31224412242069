import * as constants from 'redux/notifications/constants';

const initialState = {
  loadingAll: false,
  notifyAll: [],
  updating: false,
  fetchUpdating: false,
};

export default function notificationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.GET_ALL_USERS_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loadingAll: true,
        fetchUpdating: false,
      };

    case constants.GET_ALL_USERS_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingAll: false,
        notifyAll: payload,
      };

    case constants.GET_ALL_USERS_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loadingAll: false,
      };

    case constants.UPDATE_USER_NOTIFICATION_REQUEST:
      return {
        ...state,
        updating: true,
        fetchUpdating: false,
      };

    case constants.UPDATE_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        updating: false,
        fetchUpdating: true,
      };

    case constants.UPDATE_USER_NOTIFICATION_FAIL:
      return {
        ...state,
        updating: false,
        fetchUpdating: false,
      };

    case constants.UPDATE_USER_NOTIFY_PAGE:
      return {
        ...state,
        notifyAll: {
          ...state?.notifyAll,
          page: payload,
        },
      };   

    default:
      return state;
  }
}

// case constants.GET_ALL_USERS_NOTIFICATIONS_SUCCESS:
// const { page, rows, count,unreadCount,pageSize,
//   columns    } = action.payload;

//   return {
//     ...state,
//     loadingAll: false,
//     notifyAll: {
//       ...state.notifyAll,
//       page,
//       count,
//       unreadCount,
//       pageSize,
//       columns,
//       rows: page === 1 ? rows : [...state.notifyAll.rows, ...rows],
//     },
//   };
