import React from 'react';
import Avatar from '@mui/material/Avatar';
import * as utils from 'utils';

const MyAvatar = ({ src, name, initials }) => {
  if (src) return <Avatar src={src} alt={name} />;
  return <div className="profile-avatar">{utils.getInitials(initials)}</div>;
};

export default MyAvatar;
