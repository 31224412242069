import * as constants from 'redux/settings/app/constants';
import * as data from 'data';

const initialState = {
  loading: true,
  settings: null,
  daysList: data?.days,
  updating: false,
  refetch: false,
  loadingSource: false,
  loadingClient: false,
  leadData: [],
  clientType: [],
};

export default function appSettingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.GET_APP_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case constants.GET_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        refetch: false,
        settings: payload,
      };

    case constants.GET_APP_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case constants.UPDATE_APP_SETTINGS_REQUEST:
      return {
        ...state,
        updating: true,
      };

    case constants.UPDATE_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        updating: false,
        refetch: true,
      };

    case constants.UPDATE_APP_SETTINGS_FAIL:
      return {
        ...state,
        updating: false,
      };

    case constants.GET_LEAD_SOURCE_REQUEST:
      return {
        ...state,
        loadingSource: true,
      };

    case constants.GET_LEAD_SOURCE_SUCCESS:
      return {
        ...state,
        loadingSource: false,
        leadData: payload,
      };

    case constants.GET_LEAD_SOURCE_FAIL:
      return {
        ...state,
        loadingSource: false,
      };

    case constants.GET_CLIENT_TYPE_REQUEST:
      return {
        ...state,
        loadingClient: true,
      };

    case constants.GET_CLIENT_TYPE_SUCCESS:
      return {
        ...state,
        loadingClient: false,
        clientType: payload,
      };

    case constants.GET_CLIENT_TYPE_FAIL:
      return {
        ...state,
        loadingClient: false,
      };

    default:
      return state;
  }
}
