import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import PrivateLayout from 'components/PrivateLayout'
import { privateRoutes } from 'routes';

const PrivateRoute = ({ isAuth, children, ...props }) => {
  const location = useLocation();

  const sidebar = privateRoutes.filter((e) => !e?.hidden);
  const hidderRoute = privateRoutes?.filter((e) => e?.hidden);

  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <PrivateLayout sidebar={sidebar} hidderRoute={hidderRoute} {...props}>
      {children}
    </PrivateLayout>
  );
};

export default PrivateRoute;
