export const getAddresses = (addresses) => {
  const same = addresses?.find((e) => e?.addressType === 'same');
  const physical = addresses?.find((e) => e?.addressType === 'physical');
  const billing = addresses?.find((e) => e?.addressType === 'billing');

  if (same) {
    return {
      same: true,
      addresses: {
        address: same?.address,
        city: same?.city,
        state: same?.state,
        zip: same?.zip,
        addressType: same?.addressType,
        lat: same?.lat,
        long: same?.long,
      },
      physical: null,
      billing: null,
    };
  } else {
    return {
      same: false,
      physical: {
        address: physical?.address || '',
        city: physical?.city || '',
        state: physical?.state || '',
        zip: physical?.zip || '',
        addressType: physical?.addressType || '',
        lat: same?.lat || '',
        long: same?.long || '',
      },
      billing: {
        address: billing?.address || '',
        city: billing?.city || '',
        state: billing?.state || '',
        zip: billing?.zip || '',
        addressType: billing?.addressType || '',
        lat: same?.lat || '',
        long: same?.long || '',
      },
    };
  }
};

export const postAddresses = (data) => {
  if (!data.same) {
    return [
      {
        address: data?.address1,
        city: data?.city1,
        state: data?.state1,
        zip: data?.zip1,
        addressType: data?.addressType1 || 'physical',
        lat: data?.lat1,
        long: data?.long1,
        taxId: data?.taxId,
      },
      {
        address: data?.address2,
        city: data?.city2,
        state: data?.state2,
        zip: data?.zip2,
        addressType: data?.addressType2 || 'billing',
        lat: data?.lat2 || '',
        long: data?.long2 || '',
        taxId: data?.taxId || '',
      },
    ];
  } else {
    return [
      {
        address: data?.address1,
        city: data?.city1,
        state: data?.state1,
        zip: data?.zip1,
        lat: data?.lat1,
        long: data?.long1,
        addressType: 'same',
        taxId: data?.taxId
      },
    ];
  }
};

export const getSerialNumber = (idx, page, pageSize) => {
  const num = idx + 1;
  const count = page * pageSize - pageSize;
  return num + count;
};
