export const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAIL = 'GET_DOCUMENTS_FAIL';

export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAIL = 'UPLOAD_DOCUMENT_FAIL';

export const DOCUMENT_PROGRESS = 'DOCUMENT_PROGRESS';
export const RESET_DOCUMENT_PROGRESS = 'RESET_DOCUMENT_PROGRESS';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAIL = 'DELETE_DOCUMENT_FAIL';

export const UPDATE_DOCUMENT_NAME_REQUEST = 'UPDATE_DOCUMENT_NAME_REQUEST';
export const UPDATE_DOCUMENT_NAME_SUCCESS = 'UPDATE_DOCUMENT_NAME_SUCCESS';
export const UPDATE_DOCUMENT_NAME_FAIL = 'UPDATE_DOCUMENT_NAME_FAIL';

export const RESET_DOCUMENTS_REFETCH = 'RESET_DOCUMENTS_REFETCH';

export const UPDATE_DOCUMENTS_PAGE = 'UPDATE_DOCUMENTS_PAGE';
